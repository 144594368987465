import React, { useEffect, useState } from 'react';

const Search = ({ value, onChange, dataTestId, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (value !== undefined) {
      setSearchTerm(value);
    }
  }, [value]);

  const handleChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    onChange(newSearchTerm);
  };

  return (
    <div className='builder-relative builder-mx-auto' style={{ minWidth: 350 }}>
      <div className='builder-absolute builder-inset-y-0 builder-left-0 builder-flex builder-items-center builder-pl-3 builder-pointer-events-none'>
        <i className='far fa-search builder-text-gray-500' />
      </div>
      <input
        type='search'
        data-testid={dataTestId}
        className='builder-block builder-w-full builder-p-2 builder-pl-10 builder-text-sm builder-text-gray-900 builder-border builder-border-gray-300 builder-rounded-lg builder-bg-gray-50 builder-outline-none'
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default Search;

import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    padding: 0,
    border: '1px solid #f5f5f5'
  },
  overlay: { zIndex: 999 },
  overflow: 'visible'
};

const CustomModal = ({ isOpen, onClose, title, children, contentLabel }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false} appElement={document.getElementById('root') || undefined} style={customStyles} contentLabel={contentLabel}>
      <div className='builder-relative builder-w-full builder-max-h-full' style={{ minWidth: '36rem' }}>
        {/*Modal content*/}
        <div className='builder-relative builder-bg-white builder-rounded-lg builder-shadow'>
          {/*Modal header*/}
          <div className='builder-flex builder-items-start builder-justify-between builder-p-4 builder-border-b builder-rounded-t'>
            <h3 className='builder-text-xl builder-font-semibold builder-text-gray-900'>{title}</h3>
            <button
              type='button'
              className='builder-text-gray-400 builder-bg-transparent hover:builder-bg-gray-100 hover:builder-text-gray-900 builder-rounded-lg builder-text-sm builder-w-8 builder-h-8 builder-ml-auto builder-inline-flex builder-justify-center builder-items-center builder-builder-transition-all'
              onClick={onClose}
            >
              <i className='fal fa-times' />
              <span className='sr-only'>Close modal</span>
            </button>
          </div>
          {/*Modal body*/}
          <div className='p-6'>{children}</div>
          {/*/!*Modal footer*!/*/}
          {/*<div className="builder-flex builder-justify-end builder-items-center builder-p-6 builder-space-x-2 builder-border-t builder-border-gray-200 builder-rounded-b">*/}
          {/*  <ButtonWithIndicator*/}
          {/*    loading={isSaving}*/}
          {/*    disabled={isSaving}*/}
          {/*    onClick={onClose}*/}
          {/*    icon='fal fa-times'*/}
          {/*    text='Cancel'*/}
          {/*    colorClass='builder-bg-white builder-text-gray-700'*/}
          {/*    borderClass='builder-border builder-border-gray-300'*/}
          {/*    className='builder-ml-0 builder-mr-0'*/}
          {/*  />*/}

          {/*  <ButtonWithIndicator*/}
          {/*    loading={isSaving}*/}
          {/*    disabled={isSaving}*/}
          {/*    onClick={onSave}*/}
          {/*    icon='fal fa-save'*/}
          {/*    text='Save'*/}
          {/*    colorClass='builder-bg-primary builder-text-white'*/}
          {/*    borderClass='builder-border builder-border-bg-blue-500'*/}
          {/*    className='builder-ml-0 builder-mr-0'*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    </Modal>
  );
};

CustomModal.defaultProps = {
  contentLabel: 'Custom modal'
};

export default CustomModal;

import React, { useContext } from 'react';
import Modal from 'react-modal';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import 'react-datepicker/dist/react-datepicker.css';
import { DispatchContext, StateContext } from '../../../App';
import { modalCloseHandler, publishLogClearHandler, publishStartHandler } from '../../data/actions';
import { MODAL_TYPES } from '../../data/reducers';
import { find } from 'lodash';
import useBuilderActions from '../useBuilderActions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%'
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const PublishModal = ({ isOpen, context }) => {
  const { executeScript } = useBuilderActions();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const handlePublish = (website) => {
    publishStartHandler(dispatch);
    const project = website.isProduction ? 'website' : 'website-draft';
    executeScript('release', project, {});
  };

  const handleClose = () => {
    publishLogClearHandler(dispatch);
    modalCloseHandler(dispatch, MODAL_TYPES.PUBLISH_WEBSITE);
  };

  if (!isOpen) return null;

  const website = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = website ? website.isProduction : false;

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} contentLabel='Publish modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => handleClose()} />
      </div>

      <div className='builder-text-black builder-mb-2.5'>
        Publish <span className='builder-font-bold'>{website.name}</span>
      </div>
      <div className='builder-w-full builder-mb-4'>
        <div style={{ height: 150 }} className='builder-flex builder-flex-col-reverse builder-overflow-auto builder-bg-gray-50 builder-p-2'>
          {!state.publish.output && <div className='builder-text-gray-400 builder-text-sm builder-h-full builder-flex builder-items-center builder-justify-center'>You will see the publish process info here.</div>}
          {state.publish.output && (
            <div>
              {state.publish.output.split('\n').map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </div>
          )}
        </div>
      </div>

      {context.domainName && (
        <a href={isCurrentSiteProduction ? `https://${context.domainName}` : `https://draft.${context.domainName}`} target='_blank' rel='noreferrer' className='builder-text-blue-600 builder-cursor-pointer'>
          {isCurrentSiteProduction ? `https://${context.domainName}` : `https://draft.${context.domainName}`}
        </a>
      )}

      <div className='builder-flex builder-justify-start builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={() => handleClose()} className='builder-ml-0' text='Cancel' />

        <ButtonWithIndicator loading={false} onClick={() => handlePublish(website)} text='Publish' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>
    </Modal>
  );
};

export default PublishModal;

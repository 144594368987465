import React, { useContext } from 'react';
import SignOutButton from '../features/authentication/SignOutButton';
import { routes } from '../features/data/constants';
import { useHistory } from 'react-router-dom';
import History from './History';
import { StateContext } from '../App';
import background from '../assets/img/logo.png';

const Header = ({ renderMenu, onChangeEnvironment }) => {
  const state = useContext(StateContext);
  const isAuthenticated = state.session && state.session.authenticated;
  let history = useHistory();

  return (
    <div className='builder-flex builder-flex-row builder-h-16 builder-bg-white builder-border-b builder-border-gray-400  builder-top-0 builder-left-0 builder-right-0' style={{ height: 65, minHeight: 65 }}>
      <div className='builder-flex builder-items-center builder-ml-4 builder-justify-center builder-text-blue-800' style={{ width: 300 }}>
        <div className='builder-flex builder-items-center'>
          <img className='builder-cursor-pointer' src={background} onClick={() => history.push(routes.HOME)} alt='Logo' />
        </div>
      </div>

      {isAuthenticated && (
        <div className='builder-flex-1 builder-flex builder-items-center builder-justify-end builder-pr-2'>
          <History />
        </div>
      )}

      <div className='builder-flex-1 builder-flex builder-items-center builder-justify-end builder-pr-2 builder-border-r builder-border-gray-400' style={{ zIndex: 999 }}>
        {renderMenu && renderMenu()}
      </div>

      {/*01/08/2023 disable drafts until beter solution..*/}
      {/*{onChangeEnvironment && isAuthenticated && (*/}
      {/*  <div className='builder-flex builder-items-center builder-justify-end builder-border-r builder-border-gray-400 builder-bg-yellow-200' style={{ zIndex: 999, minWidth: 160 }}>*/}
      {/*    <EnvironmentDropDown onChangeEnvironment={onChangeEnvironment} />*/}
      {/*  </div>*/}
      {/*)}*/}

      {isAuthenticated && (
        <div className='builder-flex builder-items-center builder-justify-end builder-pr-2'>
          <SignOutButton />
        </div>
      )}
    </div>
  );
};

Header.defaultProps = {
  renderMenu: null,
  onChangeEnvironment: null
};

export default Header;

import React, { useEffect, useReducer, useState } from 'react';
import { initialState, reducer } from '../reducer';
import {
  pageTypeClearMatchPathHandler,
  pageTypeInitializeHandler,
  pageTypeSetDisabledHandler,
  pageTypeSetHandler,
  pageTypeSetInitializedHandler,
  pageTypeSetIsTemplateHandler,
  pageTypeSetMatchPathHandler,
  pageTypeSetNameHandler,
  pageTypeSetPathHandler,
  pageTypeSetSingleUseHandler
} from '../actions';
import { v4 } from 'uuid';
import Switch from '../../../../../../components/common/Switch';
import TextInput from '../../../../../builder/editors/components/TextInput';
import MatchPathField from './MatchPathField';
import cogoToast from 'cogo-toast';

const PATH_VALIDATOR_REGEX = /[^a-zA-Z\-/]/;
const MATCH_PATH_VALIDATOR_REGEX = /[^a-zA-Z:/]/;
const PAGE_TYPE_NAME_VALIDATOR_REGEX = /[^a-zA-Z_]/;

export const PageTypeUpdateMutation = `
  mutation PageTypeUpdate($data: PageTypeUpdateInputType!) {
    pageTypeUpdate(data: $data) {
        ok
    }
  }
`;

const AddEditRow = ({ id, isDraft, disabled, immutable, isTemplate, matchPath, name, path, singleUse, category, onCancel, handlePageTypesUpdate, context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const isEdit = id && id !== '';

    if (isEdit) {
      // EDIT
      const pageType = {
        id,
        isDraft,
        disabled,
        immutable,
        isTemplate,
        matchPath,
        name,
        path,
        singleUse,
        category
      };
      dispatch(pageTypeSetHandler(pageType));
    } else {
      // ADD
      const newPageTypeId = v4();
      dispatch(pageTypeInitializeHandler(newPageTypeId));
    }
  }, [id]);

  useEffect(() => {
    if (state.formInitialized) {
      setFormErrors({});
      const { errors } = validateForm();
      setFormErrors(errors);
    }
  }, [state.pageType, state.formInitialized]);

  const hasChanges = JSON.stringify(state.pageType) !== JSON.stringify(state.initPageType);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!state.pageType.name) {
      errors[`name`] = 'The name cannot be empty.';
      isValid = false;
    }

    if (state.pageType.name && PAGE_TYPE_NAME_VALIDATOR_REGEX.test(state.pageType.name)) {
      errors[`name`] = 'The name can have only letters and underscores.';
      isValid = false;
    }

    if (!state.pageType.path) {
      errors[`path`] = 'The path cannot be empty.';
      isValid = false;
    }

    if (state.pageType.path && (PATH_VALIDATOR_REGEX.test(state.pageType.path) || !state.pageType.path.startsWith('/') || !state.pageType.path.endsWith('/'))) {
      errors[`path`] = 'The path can have letters, dashes and needs to start and end with a slash.';
      isValid = false;
    }

    if (state.pageType.matchPath) {
      if (MATCH_PATH_VALIDATOR_REGEX.test(state.pageType.matchPath)) {
        errors[`matchPath`] = 'The match path can have letters, colon and slash.';
        isValid = false;
      }
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const onSave = () => {
    dispatch(pageTypeSetInitializedHandler());
    if (isValid()) {
      const pageType = state.pageType;
      setIsSaving(true);
      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({
          query: PageTypeUpdateMutation,
          variables: {
            data: {
              id: pageType.id,
              disabled: pageType.disabled,
              isDraft: pageType.isDraft,
              isTemplate: pageType.isTemplate,
              name: pageType.name,
              path: pageType.path,
              singleUse: pageType.singleUse,
              matchPath: pageType.matchPath,
              category: pageType.category
            }
          }
        })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.pageTypeUpdate?.ok) {
            cogoToast.success('Page type saved');
            handlePageTypesUpdate(pageType);
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
          }
          setIsSaving(false);
        })
        .catch((err) => {
          cogoToast.error(err);
          setIsSaving(false);
        });
    }
  };

  if (!state.pageType.id) {
    return <div className='builder-bg-gray-100 builder-animate-pulse builder-w-full' />;
  }

  return (
    <div key={id} data-testid='pageType-add-edit-form' className='builder-flex builder-items-start builder-w-full builder-shadow builder-relative'>
      {isSaving && <div className='builder-absolute builder-inset-0 builder-bg-gray-100 builder-bg-opacity-25 builder-animate-pulse' />}
      <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-flex-wrap builder-text-md builder-text-black' style={{ width: '25%', maxWidth: '25%' }}>
        <TextInput
          placeHolder='Add page type name'
          dataTestId='page-type-name-input'
          errorText={formErrors['name']}
          className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
          value={state.pageType.name}
          onChanged={(value) => dispatch(pageTypeSetNameHandler(value))}
        />
      </div>
      <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-flex-wrap builder-text-md builder-text-black' style={{ width: '20%', maxWidth: '20%' }}>
        <TextInput
          placeHolder='Add page type path'
          dataTestId='page-type-path-input'
          errorText={formErrors['path']}
          className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
          value={state.pageType.path}
          onChanged={(value) => dispatch(pageTypeSetPathHandler(value))}
        />
      </div>
      <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '25%', maxWidth: '25%' }}>
        <MatchPathField
          value={state.pageType.matchPath}
          onChange={(newMatchPath) => dispatch(pageTypeSetMatchPathHandler(newMatchPath))}
          onClear={() => dispatch(pageTypeClearMatchPathHandler())}
          errorText={formErrors['matchPath']}
          context={context}
        />
      </div>
      <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-space-y-2' style={{ width: '15%', maxWidth: '15%' }}>
        <div className='builder-flex builder-items-center builder-space-x-1'>
          <div style={{ width: 80, minWidth: 80 }}>Disabled</div>
          <Switch context={context} isChecked={state.pageType.disabled} onChange={(value) => dispatch(pageTypeSetDisabledHandler(value))} />
        </div>
        <div className='builder-flex builder-items-center builder-space-x-1'>
          <div style={{ width: 80, minWidth: 80 }}>Single use</div>
          <Switch context={context} isChecked={state.pageType.singleUse} onChange={(value) => dispatch(pageTypeSetSingleUseHandler(value))} />
        </div>
        <div className='builder-flex builder-items-center builder-space-x-1'>
          <div style={{ width: 80, minWidth: 80 }}>Is template</div>
          <Switch context={context} isChecked={state.pageType.isTemplate} onChange={(value) => dispatch(pageTypeSetIsTemplateHandler(value))} />
        </div>
      </div>
      <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md' style={{ width: '15%', maxWidth: '15%' }}>
        <div className='builder-flex builder-items-center'>
          <div
            data-testid='path-type-add-edit-form-cancel-button'
            className={`builder-flex builder-items-center builder-text-black builder-transition-all builder-underline  builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3 ${
              isSaving ? 'builder-opacity-50' : 'builder-cursor-pointer hover:builder-text-gray-800'
            }`}
            onClick={onCancel}
          >
            Cancel
          </div>

          <div
            data-testid='path-type-add-edit-form-save-button'
            className={`builder-flex builder-items-center builder-text-primary builder-transition-all ${hasChanges && !isSaving ? 'builder-group builder-cursor-pointer hover:builder-text-gray-800' : 'builder-opacity-50'}`}
            onClick={hasChanges ? () => onSave() : undefined}
          >
            <span className='builder-font-bold group-hover:builder-underline'>{isSaving ? 'Saving...' : 'Save'}</span>
            <i className='far fa-save builder-ml-1' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditRow;

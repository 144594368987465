import React, { useState } from 'react';
import { MonacoDiffEditor } from 'react-monaco-editor';
import Modal from 'react-modal';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';
import { jsonIsValid } from '../../../../utils';

const customStyles = {
  content: {
    marginRight: '50px',
    minWidth: '95%'
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const DiffEditorModal = ({ visible, leftCode, rightCode, leftTitle, rightTitle, onApply, onClose }) => {
  const [updatedCode, setUpdatedCode] = useState(JSON.stringify(rightCode, null, 2));
  const [error, setError] = useState(undefined);

  const options = {
    selectOnLineNumbers: true,
    colorDecorators: true
  };

  const handleApply = () => {
    if (updatedCode && jsonIsValid(updatedCode)) {
      onApply(updatedCode);
    } else {
      setError('Invalid Json');
    }
  };

  return (
    <Modal isOpen={visible} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} contentLabel='Publish modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => onClose()} />
      </div>

      <div className='builder-flex'>
        <div className='builder-flex-1'>{leftTitle}</div>
        <div className='builder-flex-1'>{rightTitle}</div>
      </div>
      <MonacoDiffEditor language='json' $ height='88%' original={JSON.stringify(leftCode, null, 2)} value={updatedCode} options={options} onChange={(value) => setUpdatedCode(value)} />

      {error && <div className='builder-text-red-500'>{error}</div>}

      <div className='builder-flex builder-justify-end builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={onClose} className='builder-ml-0' text='Cancel' />

        <ButtonWithIndicator loading={false} onClick={handleApply} text='Apply changes' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>
    </Modal>
  );
};
export default DiffEditorModal;

import { useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import cogoToast from 'cogo-toast';
import {
  customBlockResetFetchingHandler,
  customBlockResetSavingHandler,
  customBlockSetContentHandler,
  customBlockSetDescriptionHandler,
  customBlockSetHandler,
  customBlockSetFetchingHandler,
  customBlockSetSavingHandler,
  customBlockInitializeHandler,
  formErrorsSetHandler
} from '../actions';
import { head } from 'lodash';

// CustomBlockSearchInputType = {
//   id: (uuid optional),
//   websiteId: (uuid required)
// }

export const DataQuery = `
  query ($data: CustomBlockSearchInputType!) {
    customBlocks(data: $data) {
        id
        description
        content {
          culture
          data
          id
        }
    }
}`;

// CustomBlockUpdateInputType = {
//   id: (uuid required),
//   description: (string required)
//   websiteId: (uuid required)
//   content: [
//    {culture: 'nl', data: '<div>blah</div>', id: uuid}
//   ]
// }

export const UpdateMutation = `
  mutation ($data: CustomBlockUpdateInputType!) {
    customBlockUpdate(data: $data) {
        ok
    }
}`;

export const useCustomBlock = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = (customBlockId, websiteId) => {
    const variables = {
      data: {
        id: customBlockId,
        websiteId: websiteId
      }
    };

    dispatch(customBlockSetFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: DataQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const customBlock = head(result?.data?.customBlocks);
          if (customBlock) {
            dispatch(customBlockSetHandler(customBlock));
          } else {
            cogoToast.error('No block returned from the server.');
          }
        } else {
          cogoToast.error('No data returned from the server.');
        }
        dispatch(customBlockResetFetchingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(customBlockResetFetchingHandler());
      });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    // No empty values
    if (state.customBlock.description === '') {
      errors['customBlockDescription'] = 'The custom block description cannot be empty.';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    dispatch(formErrorsSetHandler({}));
    const { isValid, errors } = validateForm();
    dispatch(formErrorsSetHandler(errors));
    return isValid;
  };

  const onSave = (updatedCustomBlock, websiteId) => {
    if (isValid()) {
      const variables = {
        data: {
          id: updatedCustomBlock?.id,
          description: updatedCustomBlock?.description,
          websiteId: websiteId,
          content: updatedCustomBlock?.content
        }
      };

      dispatch(customBlockSetSavingHandler());
      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: UpdateMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.customBlockUpdate?.ok) {
            cogoToast.success('The custom updated successfully.');
          } else {
            cogoToast.error('The custom block could not be updated.');
          }
          dispatch(customBlockResetSavingHandler());
        })
        .catch((err) => {
          cogoToast.error('Something went wrong.');
          dispatch(customBlockResetSavingHandler());
        });
    }
  };

  const initialize = (languages) => {
    dispatch(customBlockInitializeHandler(languages));
  };

  const onUpdateDescription = (updatedDescription) => {
    dispatch(customBlockSetDescriptionHandler(updatedDescription));
  };

  const onUpdateContent = (language, updatedContent) => {
    dispatch(customBlockSetContentHandler(language, updatedContent));
  };

  return {
    fetchData: (customBlockId, websiteId) => fetchData(customBlockId, websiteId),
    initialize: (languages) => initialize(languages),
    onSave: (updatedCustomBlock, websiteId) => onSave(updatedCustomBlock, websiteId),
    onUpdateDescription: (updatedDescription) => onUpdateDescription(updatedDescription),
    onUpdateContent: (language, updatedContent) => onUpdateContent(language, updatedContent),
    fetching: state.customBlockFetching,
    saving: state.customBlockSaving,
    formErrors: state.formErrors,
    state: state
  };
};

import React, { forwardRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { datePickerLocale, monthsForLocale } from '../../utils';
import { range } from 'lodash';

const CustomInput = forwardRef(({ value, onClick, className, onChange }, ref) => (
  <div className={`builder-flex builder-items-center builder-border builder-px-3 builder-py-1.5 builder-shadow-sm builder-rounded-md builder-cursor-pointer ${className}`} onClick={onClick} ref={ref}>
    <i aria-hidden='true' className='fal fa-calendar-alt builder-mr-2' />
    <input value={value} type='text' onChange={onChange} className='focus:builder-outline-none builder-cursor-pointer builder-text-gray-700' />
  </div>
));

const DatePickerField = ({ selected, onChange, classNames, dateFormat, timeFormat, showTime, minDate, context }) => {
  // return <DatePicker selected={selected} onChange={onChange} className={classNames} customInput={<CustomInput />} dateFormat={dateFormat} minDate={minDate} />;
  const [viewMonths, setViewMonths] = useState(false);
  const [viewYears, setViewYears] = useState(false);

  const getDate = (date) => date?.getDate();
  const getYear = (date) => date?.getFullYear();
  const getMonth = (date) => date?.getMonth();
  const months = monthsForLocale(datePickerLocale(context.culture));
  const monthsShort = monthsForLocale(datePickerLocale(context.culture), 'short');
  let years = range(getYear(new Date()) - 5, getYear(new Date()) + 5, 1);

  const placeholderText = showTime ? '--/--/---- --:--' : '--/--/----';
  const formatOfTheDate = dateFormat ? dateFormat : showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';

  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,

    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled
  }) => {
    const currentMonthIndex = getMonth(date);
    const currentMonth = months[currentMonthIndex];
    const currentYear = getYear(date);

    const renderIconButton = (icon, onClick, disabled) => (
      <div onClick={onClick} className={`builder-w-10 builder-h-10 builder-flex builder-items-center builder-justify-center ${disabled ? 'builder-opacity-20 builder-pointer-events-none' : 'builder-cursor-pointer'}`}>
        <i className={`${icon} builder-text-2xl builder-w-6`} />
      </div>
    );

    const renderMonthsView = () => (
      <div className='builder-absolute builder-inset-0 builder-rounded builder-bg-gray-100 builder-flex builder-flex-col'>
        <div className='builder-flex builder-flex-row builder-justify-between builder-items-center builder-font-primary builder-h-16 builder-px-14 builder-border-b builder-border-gray-500'>
          <div className='builder-flex builder-flex-row builder-gap-2 builder-items-center builder-cursor-pointer' onClick={() => setViewMonths(false)}>
            <span className='builder-text-[16px] builder-leading-5 builder-tracking-wide builder-font-normal builder-capitalize'>{monthsShort[getMonth(date)]}</span>
            <i className='fa fa-caret-down builder-text-sm builder-w-4 builder-h-4 builder-flex builder-items-center builder-justify-center' />
          </div>

          <div className='builder-flex builder-flex-row builder-gap-2 builder-items-center builder-opacity-20'>
            <span className='builder-text-[16px] builder-leading-5 builder-tracking-wide'>{getYear(date)}</span>
          </div>
        </div>
        <div className='builder-overflow-y-scroll builder-flex builder-flex-col builder-flex-1'>
          {months.map((month, index) => {
            const disabled = (prevMonthButtonDisabled && index < currentMonthIndex) || (nextMonthButtonDisabled && index > currentMonthIndex);
            const isSelected = month == currentMonth;
            const handleClickMonth = (e) => {
              if (disabled) return;
              changeMonth(months.indexOf(month));
              setViewMonths(false);
            };

            return (
              <div
                key={index}
                className={`builder-flex builder-flex-row builder-gap-4 builder-pl-4 builder-pr-6 builder-min-h-[48px] builder-items-center hover:builder-bg-gray-600 hover:builder-bg-opacity-10 ${
                  isSelected ? 'builder-bg-gray-600 builder-bg-opacity-10' : 'builder-cursor-pointer'
                } ${disabled ? 'builder-opacity-30' : ''}`}
                onClick={handleClickMonth}
              >
                {isSelected && <i className='fal fa-check builder-w-6 builder-h-6 builder-text-[16px] builder-flex builder-items-center builder-justify-center' />}
                {!isSelected && <div className='builder-w-6 builder-h-6' />}
                <span className='builder-text-[16px] builder-font-regular builder-leading-normal builder-tracking-wide builder-capitalize'>{month}</span>
              </div>
            );
          })}
        </div>
      </div>
    );

    const renderYearView = () => (
      <div className='builder-absolute builder-inset-0 builder-rounded builder-bg-gray-100 builder-flex builder-flex-col'>
        <div className='builder-flex builder-flex-row builder-justify-between builder-items-center builder-font-primary builder-h-16 builder-px-14 builder-border-b builder-border-gray-500'>
          <div className='builder-flex builder-flex-row builder-gap-2 builder-items-center builder-opacity-20'>
            <span className='builder-text-[16px] builder-leading-5 builder-tracking-wide builder-font-normal builder-capitalize'>{monthsShort[getMonth(date)]}</span>
            <i className='fa fa-caret-down builder-text-sm builder-w-4 builder-h-4 builder-flex builder-items-center builder-justify-center' />
          </div>

          <div className='builder-flex builder-flex-row builder-gap-2 builder-items-center  builder-cursor-pointer' onClick={() => setViewYears(false)}>
            <span className='builder-text-[16px] builder-leading-5 builder-tracking-wide'>{getYear(date)}</span>
          </div>
        </div>
        <div className='builder-overflow-y-scroll builder-flex builder-flex-col builder-flex-1'>
          {years.map((year, index) => {
            const disabled = (prevYearButtonDisabled && year < currentYear) || (nextYearButtonDisabled && year > currentYear);
            const isSelected = year == currentYear;
            const handleClickYear = (e) => {
              if (disabled) return;
              changeYear(year);
              setViewYears(false);
            };

            return (
              <div
                key={index}
                className={`builder-flex builder-flex-row builder-gap-4 builder-pl-4 builder-pr-6 builder-min-h-[48px] builder-items-center hover:builder-bg-gray-600 hover:builder-bg-opacity-10 ${
                  isSelected ? 'builder-bg-gray-600 builder-bg-opacity-10' : 'builder-cursor-pointer'
                } ${disabled ? 'builder-opacity-30' : ''}`}
                onClick={handleClickYear}
              >
                {isSelected && <i className='fal fa-check builder-w-6 builder-h-6 builder-text-[16px] builder-flex builder-items-center builder-justify-center' />}
                {!isSelected && <div className='builder-w-6 builder-h-6' />}
                <span className='builder-text-[16px] builder-font-regular builder-leading-normal builder-tracking-wide builder-capitalize'>{year}</span>
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <div className='builder-flex builder-flex-row builder-justify-between builder-font-primary builder-items-center builder-h-16'>
        <div className='builder-flex builder-flex-row builder-items-center builder-h-12'>
          {renderIconButton('fal fa-angle-left', decreaseMonth, prevMonthButtonDisabled)}
          <div className='builder-flex builder-flex-row builder-gap-2 builder-items-center builder-cursor-pointer' onClick={() => setViewMonths(true)}>
            <span className='builder-text-[16px] builder-leading-5 builder-tracking-wide builder-font-normal builder-capitalize'>{monthsShort[getMonth(date)]}</span>
            <i className='fa fa-caret-down builder-text-sm builder-w-4 builder-h-4 builder-flex builder-items-center builder-justify-center' />
          </div>
          {renderIconButton('fal fa-angle-right', increaseMonth, nextMonthButtonDisabled)}
        </div>
        <div className='builder-flex builder-flex-row builder-items-center builder-h-12'>
          {renderIconButton('fal fa-angle-left', decreaseYear, prevYearButtonDisabled)}
          <div className='builder-flex builder-flex-row builder-gap-2 builder-items-center builder-cursor-pointer' onClick={() => setViewYears(true)}>
            <span className='builder-text-sm builder-leading-5 builder-tracking-wide'>{getYear(date)}</span>
            <i className='fa fa-caret-down builder-text-sm builder-w-4 builder-h-4 builder-flex builder-items-center builder-justify-center' />
          </div>
          {renderIconButton('fal fa-angle-right', increaseYear, nextYearButtonDisabled)}
        </div>

        {viewMonths && renderMonthsView()}
        {viewYears && renderYearView()}
      </div>
    );
  };

  const renderDayContents = (day, date) => {
    return <div className='builder-w-full builder-h-full builder-flex builder-items-center builder-justify-center builder-p-4'>{getDate(date)}</div>;
  };

  return (
    <DatePicker
      className={'builder-relative builder-border focus:builder-border-primary builder-px-3 builder-py-2 builder-gap-1 builder-rounded-md builder-bg-white builder-border-gray-300 builder-w-full'}
      onChange={onChange}
      selected={selected !== '' ? new Date(selected) : selected}
      placeholderText={placeholderText}
      minDate={minDate}
      locale={datePickerLocale(context.culture)}
      renderCustomHeader={renderCustomHeader}
      dayClassName={(date) => `builder-font-primary builder-text-[16px] builder-w-10 builder-h-10 builder-leading-normal builder-tracking-wide builder-font-regular`}
      weekDayClassName={() => `builder-font-primary builder-text-[16px] builder-w-10 builder-leading-normal builder-tracking-wide builder-font-semibold builder-uppercase`}
      timeClassName={(time) => `builder-font-primary builder-text-[16px] builder-h-10 builder-leading-normal builder-tracking-wide builder-font-regular`}
      renderDayContents={renderDayContents}
      calendarClassName='builder-bg-gray-100 builder-rounded border border-gray-100'
      useWeekdaysShort={false}
      formatWeekDay={(day) => day.substring(0, 1)}
      showTimeSelect={showTime}
      timeFormat={timeFormat}
      dateFormat={formatOfTheDate}
      customInput={<CustomInput />}
    />
  );
};

DatePickerField.defaultProps = {
  timeFormat: 'HH:mm',
  showTime: true
};

export default DatePickerField;

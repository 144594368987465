import { map } from 'lodash';
import { v4 } from 'uuid';

export const initialCustomBlockContent = {
  id: '',
  culture: '',
  data: '<div>Add your HTML here</div>'
};

export const initialCustomBlock = {
  id: '',
  description: '',
  content: []
  // website:
};

export const initialState = {
  customBlock: initialCustomBlock,
  formErrors: {},
  customBlockFetching: false,
  customBlockSaving: false
};

// ACTION TYPES //
export const actionTypes = {
  CUSTOM_BLOCK_SET: 'CUSTOM_BLOCK_SET',
  CUSTOM_BLOCK_INITIALIZE: 'CUSTOM_BLOCK_INITIALIZE',
  CUSTOM_BLOCK_SET_DESCRIPTION: 'CUSTOM_BLOCK_SET_DESCRIPTION',
  CUSTOM_BLOCK_SET_CONTENT: 'CUSTOM_BLOCK_SET_CONTENT',
  CUSTOM_BLOCK_SET_FETCHING: 'CUSTOM_BLOCK_SET_FETCHING',
  CUSTOM_BLOCK_RESET_FETCHING: 'CUSTOM_BLOCK_RESET_FETCHING',
  CUSTOM_BLOCK_SET_SAVING: 'CUSTOM_BLOCK_SET_SAVING',
  CUSTOM_BLOCK_RESET_SAVING: 'CUSTOM_BLOCK_RESET_SAVING',
  FORM_ERRORS_SET: 'FORM_ERRORS_SET'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CUSTOM_BLOCK_SET_FETCHING:
      return {
        ...state,
        customBlockFetching: true
      };
    case actionTypes.CUSTOM_BLOCK_RESET_FETCHING:
      return {
        ...state,
        customBlockFetching: false
      };
    case actionTypes.CUSTOM_BLOCK_SET:
      return {
        ...state,
        customBlock: action.payload.customBlock,
        customBlockFetching: false
      };
    case actionTypes.CUSTOM_BLOCK_INITIALIZE:
      return {
        ...state,
        customBlock: {
          ...initialCustomBlock,
          id: v4(),
          content: map(action.payload.languages, (language) => ({
            ...initialCustomBlockContent,
            culture: language,
            id: v4()
          }))
        }
      };
    case actionTypes.CUSTOM_BLOCK_SET_SAVING:
      return {
        ...state,
        customBlockSaving: true
      };
    case actionTypes.CUSTOM_BLOCK_RESET_SAVING:
      return {
        ...state,
        customBlockSaving: false
      };
    case actionTypes.CUSTOM_BLOCK_SET_DESCRIPTION:
      return {
        ...state,
        customBlock: {
          ...state.customBlock,
          description: action.payload.updatedDescription
        }
      };
    case actionTypes.CUSTOM_BLOCK_SET_CONTENT:
      return {
        ...state,
        customBlock: {
          ...state.customBlock,
          content: map(state.customBlock.content, (content) => {
            if (action.payload.language === content.culture) {
              return { ...content, data: action.payload.updatedContent };
            }

            return content;
          })
        }
      };
    case actionTypes.FORM_ERRORS_SET:
      return {
        ...state,
        formErrors: action.payload.formErrors
      };
    default:
      return state;
  }
};

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../../../data/constants';
import ButtonWithIndicator from '../../../../../components/common/ButtonWithIndicator';
import TextInput from '../../../../builder/editors/components/TextInput';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { find, findKey, forEach, get, head, isEmpty, map } from 'lodash';
import { v4 } from 'uuid';
import { StateContext } from '../../../../../App';

import cogoToast from 'cogo-toast';

import { initialContent, initialState, reducer } from '../reducer';
import WysiwygEditor from '../../../../../components/common/WysiwygEditor';
import {
  categoriesAddHandler,
  categoriesResetFetchingHandler,
  categoriesSetFetchingHandler,
  categoriesSetHandler,
  topicInitializeHandler,
  topicResetLoadingHandler,
  topicResetSavingHandler,
  topicSetContentHandler,
  topicSetHandler,
  topicSetInitializedHandler,
  topicSetLoadingHandler,
  topicSetSaveCompletedHandler,
  topicSetSavingHandler,
  topicSetSlugHandler,
  topicSetTitleHandler,
  topicUndoChangesHandler,
  topicUpdateCategoryIdsHandler
} from '../actions';
import LoadingSkeleton from './LoadingSkeleton';
import { containsOnlyLowercaseAndHyphens, orderByPriority } from '../../../utils';
import CategorySelector from './CategorySelector';
import CustomModal from '../../../../../components/common/Modal';
import { default as CategoryDetail } from '../../../categories/detail/components/Detail';

export const CategoriesQuery = `
  query CategoriesQuery {
    faqCategories {
        id
        title        
        priority
    }    
}`;

export const TopicQuery = `query FaqTopics($data: FaqTopicSearchInputType!) { 
  faqTopics(data: $data) { 
    id
    title
    slug
    content {
        id
        culture
        data
    }
    categoryIds
    priority
    isDraft
    disabled
  } 
}`;

export const TopicSaveMutation = `mutation FaqTopicUpdate($data: FaqTopicUpdateInputType!) {
  faqTopicUpdate(data: $data) {
      ok
  }
}`;

const Detail = ({ context, currentLanguage, setCurrentLanguage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useContext(StateContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formErrors, setFormErrors] = useState({});
  const { id: idFromUrl } = useParams();
  const languages = globalState?.configuration?.website?.languages;

  const history = useHistory();
  const location = useLocation();
  const isCreate = location?.state?.isCreate;

  const fetchTopic = (topicId) => {
    dispatch(topicSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: TopicQuery, variables: { data: { id: topicId } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const topic = head(result?.data.faqTopics);
          if (!topic) {
            dispatch(topicInitializeHandler(idFromUrl));
          } else {
            dispatch(topicSetHandler(topic));
          }
          dispatch(topicResetLoadingHandler());
        } else {
          dispatch(topicResetLoadingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(topicResetLoadingHandler());
        cogoToast.error('Something went wrong.');
      });
  };

  const fetchCategories = () => {
    dispatch(categoriesSetFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CategoriesQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const categories = result?.data?.faqCategories;
          dispatch(categoriesSetHandler(categories));
        } else {
          dispatch(categoriesResetFetchingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(categoriesResetFetchingHandler());
        cogoToast.error(`Something went wrong. ${err}`);
      });
  };

  useEffect(() => {
    if (!isCreate) {
      fetchTopic(idFromUrl);
    } else {
      dispatch(topicInitializeHandler(idFromUrl));
    }
    fetchCategories();
    history.replace(`${routes.FAQ_TOPIC_DETAIL}/${idFromUrl}`);
  }, []);

  useEffect(() => {
    if (state.formInitialized) {
      setFormErrors({});
      const { errors } = validateForm();
      setFormErrors(errors);
    }
  }, [state.topic, state.formInitialized]);

  if (state.loading || state.categoriesFetching) {
    return <LoadingSkeleton />;
  }

  const topic = state.topic;

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    forEach(languages, (language) => {
      if (isEmpty(topic.title[language])) {
        errors[`title-${language}`] = 'The title needs to be added in all languages.';
        isValid = false;
      }

      if (!containsOnlyLowercaseAndHyphens(topic.slug[language])) {
        errors[`slug-${language}`] = 'The slug can only have lowercase chars and -';
        isValid = false;
      }

      if (isEmpty(topic.slug[language])) {
        errors[`slug-${language}`] = 'The slug needs to be added in all languages.';
        isValid = false;
      }
    });

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const saveTopic = ({ addAnother }) => {
    dispatch(topicSetInitializedHandler());
    if (isValid()) {
      dispatch(topicSetSavingHandler());
      const variables = {
        data: {
          id: topic.id,
          title: JSON.stringify(topic.title),
          slug: JSON.stringify(topic.slug),
          content: topic.content,
          categoryIds: topic.categoryIds,
          priority: topic.priority,
          disabled: topic.disabled,
          isDraft: topic.isDraft
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: TopicSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.faqTopicUpdate?.ok) {
            cogoToast.success('Topic saved');
            dispatch(topicSetSaveCompletedHandler());
            if (addAnother) {
              const newId = v4();
              dispatch(topicInitializeHandler(newId));
              history.replace(`${routes.FAQ_TOPIC_DETAIL}/${newId}`);
            }
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
            dispatch(topicResetSavingHandler());
          }
        })
        .catch((err) => {
          cogoToast.error('Something went wrong. The topic could not be saved.');
          dispatch(topicResetSavingHandler());
        });
    }
  };

  const onCreateCategory = () => {
    setIsModalOpen(true);
  };

  const handleCategorySave = (category) => {
    dispatch(categoriesAddHandler(category));
    setIsModalOpen(false);
  };

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  const isLoading = state.saving || state.loading || state.categoriesFetching;

  return (
    <div className='builder-flex builder-flex-col builder-flex-1 builder-p-5'>
      <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
        <div className='builder-flex builder-flex-row builder-items-center'>
          <Link to={`${routes.FAQ_TOPICS}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
            <i className='fa fa-chevron-left builder-mr-2' />
            Overview
          </Link>
          <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Topic</div>
        </div>
        <div className='flex'>
          {state.history === undefined && (
            <ButtonWithIndicator
              loading={isLoading}
              disabled={isLoading}
              onClick={() => history.push(`${routes.FAQ_TOPICS}`)}
              icon='fal fa-times'
              text='Cancel'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          )}
          {state.history && (
            <ButtonWithIndicator
              loading={isLoading}
              disabled={state.history.length === 1 || isLoading}
              onClick={() => dispatch(topicUndoChangesHandler())}
              icon='fal fa-undo'
              text='Undo changes'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          )}

          <ButtonWithIndicator
            loading={isLoading}
            disabled={isLoading}
            onClick={saveTopic}
            icon='fal fa-save'
            text='Save'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />

          <ButtonWithIndicator
            loading={isLoading}
            disabled={isLoading}
            onClick={() => saveTopic({ addAnother: true })}
            icon='far fa-layer-plus'
            text='Save and add another'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />
        </div>
      </div>
      <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50 builder-relative'>
        {isLoading && <div className='builder-absolute builder-inset-0 builder-bg-gray-200 builder-z-20 builder-opacity-30' />}

        <div className='builder-w-3/4 builder-mr-5'>
          <div className='builder-flex builder-w-full h-full'>
            <div className='builder-w-full'>
              <div className='builder-flex builder-flex-col builder-mb-5'>
                <Tabs defaultIndex={0}>
                  <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
                    {map(languages, (language) => {
                      const hasErrors = findKey(formErrors, (value, key) => key.endsWith(`-${language}`));
                      const className = `${tabClassNames} ${hasErrors ? 'builder-bg-red-500' : ''}`;
                      return (
                        <Tab key={`question-${language}`} className={className} selectedClassName={activeTabClassNames}>
                          {language}
                        </Tab>
                      );
                    })}
                  </TabList>

                  {map(languages, (language) => {
                    const topicTitle = get(topic.title, language);
                    const topicSlug = get(topic.slug, language);
                    const topicContent = find(topic?.content, (content) => content.culture === language);
                    return (
                      <TabPanel key={`question-content-${language}`} className={tabPanelClassNames}>
                        <div className='builder-flex builder-flex-col builder-p-4 builder-space-y-4 builder-border-b builder-border-gray-300'>
                          {/*TITLE*/}
                          <div>
                            <TextInput
                              dataTestId='topic-input-title'
                              placeHolder='Add topic title'
                              label='Title'
                              disabled={isLoading}
                              errorText={formErrors[`title-${language}`]}
                              className='builder-flex builder-flex-1 builder-border builder-max-w-xl builder-rounded-md'
                              value={topicTitle}
                              onChanged={(value) => dispatch(topicSetTitleHandler(value, language))}
                            />
                          </div>

                          {/*SLUG*/}
                          <div>
                            <TextInput
                              dataTestId='topic-input-slug'
                              placeHolder='Add topic slug'
                              label='Slug'
                              errorText={formErrors[`slug-${language}`]}
                              disabled={isLoading}
                              className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
                              value={topicSlug}
                              onChanged={(value) => dispatch(topicSetSlugHandler(value, language))}
                            />
                          </div>

                          {/*CONTENT*/}
                          <div>
                            <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Content</div>
                            <WysiwygEditor data={topicContent?.data || initialContent.data} onChange={(newValue) => dispatch(topicSetContentHandler(topicContent?.id, newValue, language))} />
                          </div>
                        </div>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className='builder-w-1/4'>
          <CategorySelector
            categories={orderByPriority(state.categories)}
            selectedCategoryIds={topic.categoryIds}
            onCategoryClick={(categoryId) => dispatch(topicUpdateCategoryIdsHandler(categoryId))}
            currentLanguage={currentLanguage}
            handleCreateCategory={onCreateCategory}
          />
        </div>
      </div>
      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} title='Add new category'>
        <CategoryDetail
          context={context}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          modal={{
            onClose: () => setIsModalOpen(!isModalOpen),
            onSave: (category) => handleCategorySave(category)
          }}
        />
      </CustomModal>
    </div>
  );
};

export default Detail;

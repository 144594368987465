import React from 'react';
import MonacoEditor from 'react-monaco-editor';

const ConfigurationEditor = ({ configuration, isSuperUser, onChange }) => {
  if (!isSuperUser) {
    return <div> not allowed </div>;
  }

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const options = {
    selectOnLineNumbers: true,
    colorDecorators: true
  };

  return (
    <div className='builder-flex builder-flex-1 builder-h-full builder-mb-6'>
      <MonacoEditor language='json' theme='vs-dark' onChange={handleChange} options={options} defaultValue={JSON.stringify(JSON.parse(configuration), null, 2)} />
    </div>
  );
};

export default ConfigurationEditor;

import React from 'react';
import { map } from 'lodash';

const MATCH_PATH_FIELDS = [':make', ':model', ':reference', ':category', ':topic'];
const MatchPathField = ({ value, onChange, onClear, errorText, context }) => {
  const hasValue = value && value !== '';

  return (
    <div>
      <div>
        <div data-testid='pageType-match-path-labels' className='builder-flex builder-items-center builder-space-x-2 builder-mb-1 builder-pb-1 builder-border-b builder-border-gray-50 builder-overflow-y-scroll scrollbar'>
          {map(MATCH_PATH_FIELDS, (matchField) => {
            return (
              <div key={matchField} className='builder-text-sm builder-cursor-pointer builder-text-black hover:builder-bg-gray-300 builder-transition-all builder-p-1 builder-rounded builder-bg-gray-100' onClick={() => onChange(matchField)}>
                {matchField}
              </div>
            );
          })}
        </div>

        <span className='builder-mr-2'>Current:</span>
        {hasValue && (
          <span className='font-bold builder-mr-2' data-testid='pathType-current-match-type'>
            {value}
          </span>
        )}
        {hasValue ? <i className='far fa-times builder-text-gray-700 builder-cursor-pointer hover:text-gray-600 builder-transition-all' onClick={onClear} /> : <i className='fad fa-minus' />}
      </div>
      {errorText && (
        <div data-testid='pathType-current-match-type-error' className='builder-mt-1 builder-text-sm builder-text-red-500 builder-whitespace-normal'>
          {errorText}
        </div>
      )}
    </div>
  );
};

export default MatchPathField;

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../../features/data/constants';
import { StateContext } from '../../App';
import { LoadingIndicator } from './index';

const PrivateRoute = ({ children, context, ...rest }) => {
  const state = useContext(StateContext);

  if (state.session === undefined) {
    return <LoadingIndicator />;
  }

  const renderRoute = ({ location }) => {
    if (state.session.authenticated) {
      return children;
      // return (
      //     <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
      //         {children}
      //     </Template>
      // )
    } else {
      return (
        <Redirect
          to={{
            pathname: routes.LOGIN,
            state: { from: location }
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;

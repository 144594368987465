import React, { useState, useEffect, useContext } from 'react';
import { filter, find, map, upperFirst, values } from 'lodash';
import CopyContentFrom from './CopyContentFrom';
import { definitionsForContentType, getContentForField, getHumanText } from '../../../utils';
import FieldEditor from './FieldEditor';
import DistributeContentTo from './DistributeContentTo';
import { StateContext } from '../../../App';
import Dropdown from '../../../components/common/Dropdown';
import Toggle from 'react-toggle';

const BlockEditor = ({ block, pages, campaigns, currentPage, currentLanguage, contentTypeDefinitions, onCopyBlock, onCopyBlockToAll, onContentChange, onCampaignChange, onHideForCampaignChange, context }) => {
  const [currentField, setCurrentField] = useState(undefined);
  const [isOpen, setIsOpen] = useState(true);
  const state = useContext(StateContext);
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;
  const availableCampaigns = () => {
    if (isCurrentSiteProduction) {
      return filter(campaigns, (c) => !c.disabled && !c.isDraft);
    } else {
      return filter(campaigns, (c) => !c.disabled && c.isDraft);
    }
  };

  useEffect(() => {
    setCurrentField(undefined);
  }, [block.componentName]);

  const definitions = definitionsForContentType(contentTypeDefinitions, block.contentType);
  // if(definitions.length === 1 && currentField === undefined) {
  //     setCurrentField(definitions[0])
  // }

  return (
    <React.Fragment>
      {!currentField && (
        <div className=''>
          <div className='builder-flex builder-flex-col'>
            <div className={`builder-pt-2 builder-pb-4 ${isOpen ? 'builder-border-b builder-border-gray-300 builder-shadow-sm' : 'builder-mb-2'}`}>
              <div className='builder-flex builder-justify-between builder-items-center builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
                <h3 className='builder-font-bold builder-text-lg builder-ml-5'>{getHumanText(block.componentName, 'en')}</h3>
                <i className={`fas builder-mr-5 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
              </div>
              <div className='builder-flex builder-px-5'>
                <div className='builder-mr-4'>
                  <DistributeContentTo onClick={() => onCopyBlockToAll(block, currentLanguage)} />
                </div>

                <CopyContentFrom contentType={block.contentType} allPages={pages} currentPage={currentPage} language={currentLanguage} onCopy={(copyBlock) => onCopyBlock(block.id, copyBlock, currentLanguage)} />
              </div>
            </div>

            {/*<div className="builder-flex builder-flex-col builder-mb-4">*/}
            {/*    <span className="builder-font-bold builder-text-lg"> {getHumanText(block.componentName, 'en')}</span>*/}

            {/*    <div className="builder-flex builder-justify-between">*/}
            {/*        <span className="builder-text-sm">ref: b{block.id.split('-')[0]}</span>*/}

            {/*        <div className="builder-flex builder-flex-end">*/}
            {/*            <div className="builder-mr-4">*/}
            {/*                <DistributeContentTo onClick={() => onCopyBlockToAll(block, currentLanguage)}/>*/}
            {/*            </div>*/}

            {/*            <CopyContentFrom contentType={block.contentType}*/}
            {/*                             allPages={pages}*/}
            {/*                             currentPage={currentPage}*/}
            {/*                             language={currentLanguage}*/}
            {/*                             onCopy={(copyBlock) => onCopyBlock(block.id, copyBlock, currentLanguage)}*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*    </div>*/}

            {/*</div>*/}
          </div>
          <div className={`${isOpen ? 'builder-flex builder-flex-col ' : 'builder-hidden'}`}>
            <div className='builder-p-5'>
              {map(definitions, (definition, i) => {
                const isLast = i === definitions.length - 1;
                return (
                  <div
                    key={`${definition.name}_${i}`}
                    className={`builder-flex builder-items-center builder-justify-between builder-cursor-pointer builder-p-2 hover:builder-bg-gray-100 ${isLast ? '' : 'builder-mb-6 builder-border-b'}`}
                    onClick={() => setCurrentField(definition)}
                  >
                    <div className='builder-flex builder-flex-col'>
                      <label className='builder-block builder-text-black builder-text-md builder-font-bold '>{upperFirst(definition.label)}</label>
                      {definition.description && <div className='builder-italic builder-text-sm builder-text-gray-700 builder-mt-2'>{definition.description}</div>}
                    </div>
                    <i className='fal fa-chevron-right builder-text-gray-400' style={{ fontSize: 14 }} />
                  </div>
                );
              })}
            </div>

            {availableCampaigns().length > 0 && (
              <div className='builder-flex builder-flex-col builder-bg-gray-100 builder-py-5 builder-border-t builder-border-gray-300'>
                <div className='builder-font-bold mb-2 builder-text-black builder-px-5'>Campaign</div>

                <div className='builder-flex builder-flex-1 builder-px-5'>
                  <Dropdown
                    options={map(availableCampaigns(), (c) => ({
                      key: c.id,
                      value: c.name
                    }))}
                    value={block.campaignId}
                    maxWidth='100%'
                    placeHolder='Select a campaign'
                    borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
                    selectClassName='builder-py-2 builder-rounded-md'
                    renderItem={(item) => {
                      return (
                        <div className='builder-flex builder-justify-between builder-p-2' onClick={() => onCampaignChange(block.id, item.key)}>
                          <span className='builder-text-sm'>{item.value}</span>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className='builder-flex builder-w-full builder-mb-4 builder-mt-4 builder-px-5'>
                  <div className='builder-flex builder-flex-col builder-flex-1 builder-justify-center builder-ml-2'>Hide this component during campaign</div>
                  <div className='builder-flex builder-flex-col builder-justify-center builder-items-end'>
                    <Toggle
                      defaultChecked={false}
                      checked={block.hideForCampaign}
                      onChange={(e) => {
                        onHideForCampaignChange(block.id, e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {currentField && (
        <React.Fragment>
          <FieldEditor
            definition={currentField}
            value={getContentForField(block, currentField.name, currentLanguage)}
            onChange={(field, value) => onContentChange(block.id, field, value, currentLanguage)}
            context={{ ...context, pages: values(pages), campaigns: campaigns }}
            breadcrumbs={[getHumanText(block.componentName, 'en'), upperFirst(currentField.label)]}
            onBack={(e) => {
              setCurrentField(undefined);
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default BlockEditor;

import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useSignOut } from '../../authentication/useSignout';

const ForceSignOut = () => {
  const { signOut } = useSignOut();
  return (
    <div className='builder-text-black'>
      <h3 className='builder-text-center'>Ending session</h3>

      <div className='builder-py-8 builder-flex builder-flex-col builder-justify-center builder-text-center'>
        <p>Your session has ended by another user </p>
        <div />
      </div>

      <div className='builder-flex builder-justify-center builder-mb-6' style={{ fontSize: 16 }}>
        <CountdownCircleTimer
          isPlaying
          duration={3}
          colors={[
            ['#004777', 0.33],
            ['#F7B801', 0.33],
            ['#A30000', 0.33]
          ]}
          size={120}
          onComplete={() => signOut()}
        >
          {({ remainingTime }) => {
            return (
              <div className='builder-flex builder-flex-col builder-items-center'>
                <div className='time'>{Math.ceil(remainingTime)}</div>
                <div>seconds</div>
              </div>
            );
          }}
        </CountdownCircleTimer>
      </div>
    </div>
  );
};

export default ForceSignOut;

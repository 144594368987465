import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { map, orderBy, filter, concat, find, upperFirst, camelCase, includes, isEmpty } from 'lodash';
import { Container, Draggable } from 'react-smooth-dnd';
import { routes } from '../data/constants';
import TextInput from '../builder/editors/components/TextInput';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { initialDetailState, detailReducer } from './reducers';
import { blockAddFieldHandler, blockDeleteFieldHandler, blockInitializeHandler, blockSetFieldNameHandler, blockSetIsTemplateHandler, blockSetNameHandler, blockUndoChangesHandler, detailBlockSetHandler, detailFieldTypesSetHandler } from './actions';
import cogoToast from 'cogo-toast';
import { hasMultipleTypes } from './utils';
import 'highlight.js/styles/github.css';
import ComponentPreview from './ComponentPreview';

export const BlockQuery = `query Block($data: BlockSearchInputType!) { 
  blocks(data: $data) { 
      id
      name
      fields {
          name
          type
      }
      isTemplate
      readonly 
  }
  
  fieldTypes {
    name
    label
    contentType
    fieldType
    dataSchema
  }   
}`;

export const BlockSaveMutation = `mutation BlockUpdate($data: BlockUpdateInputType!) { 
  blockUpdate(data: $data) { 
      ok
  }
}`;

const Detail = ({ props }) => {
  const [state, dispatch] = useReducer(detailReducer, initialDetailState);
  const { id: idFromUrl } = useParams();
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetchBlock(idFromUrl);
  }, []);

  const fetchBlock = (id) => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: BlockQuery, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const block = result && result.data && result.data.blocks && result.data.blocks.length > 0 ? result.data.blocks[0] : undefined;
        if (block) {
          detailBlockSetHandler(dispatch, block);
        } else {
          blockInitializeHandler(dispatch, id);
        }
        detailFieldTypesSetHandler(dispatch, result.data.fieldTypes);
      })
      .catch((err) => {});
  };

  const validateForm = () => {
    let isValid = false;
    let errors = {};

    // No empty values
    if (state.block.name === '') {
      errors['blockName'] = 'The block name cannot be empty.';
      isValid = false;
    }
    if (state.block.fields === undefined || state.block.fields.length < 1) {
      errors['blockFields'] = 'The block must have blockFields assigned.';
      isValid = false;
    }

    // block.name must start with capital
    if (state.block.name !== upperFirst(state.block.name)) {
      errors['blockName'] = 'The block name must start with a capital. eg.' + upperFirst(state.block.name);
      isValid = false;
    }

    let fieldErrors = [];
    map(state.block.fields, (field) => {
      // field.name cannot be empty
      if (field.name === '') {
        fieldErrors = [
          ...fieldErrors,
          {
            fieldId: field.id,
            errorMsg: 'The field name cannot be empty.'
          }
        ];
      }

      // field.name must be in camelCase
      if (field.name !== camelCase(field.name)) {
        fieldErrors = [
          ...fieldErrors,
          {
            fieldId: field.id,
            errorMsg: 'The field name must be in camelCase. eg.' + camelCase(field.name)
          }
        ];
      }

      // field.name cannot contain _
      if (includes(field.name, '_')) {
        fieldErrors = [
          ...fieldErrors,
          {
            fieldId: field.id,
            errorMsg: 'The field name cannot contain _'
          }
        ];
      }
    });

    if (fieldErrors.length > 0) {
      errors['blockFieldsData'] = fieldErrors;
    }

    if (isEmpty(errors)) {
      isValid = true;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const saveBlock = (block) => {
    if (isValid()) {
      const variables = {
        data: {
          id: block.id,
          name: block.name,
          fields: map(block.fields, (field) => ({
            name: field.name,
            type: field.type
          })),
          isTemplate: block.isTemplate
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: BlockSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.blockUpdate?.ok) {
            cogoToast.success('Block Saved');
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
          }
        })
        .catch((err) => {});
    }
  };

  const renderFieldTypes = () => {
    if (state.fieldTypes && state.fieldTypes.length > 0) {
      const sortedFieldTypes = orderBy(state.fieldTypes, ['contentType', 'fieldType', 'label'], ['asc', 'desc', 'asc']);

      const singleItemsWithMultipleFieldTypes = filter(sortedFieldTypes, (i) => {
        return hasMultipleTypes(i, sortedFieldTypes) && i.fieldType === 'single';
      });
      const listItemsWithMultipleFieldTypes = filter(sortedFieldTypes, (i) => {
        return hasMultipleTypes(i, sortedFieldTypes) && i.fieldType === 'list';
      });
      const singleItems = filter(sortedFieldTypes, (i) => {
        return !hasMultipleTypes(i, sortedFieldTypes) && i.fieldType === 'single';
      });
      const listItems = filter(sortedFieldTypes, (i) => {
        return !hasMultipleTypes(i, sortedFieldTypes) && i.fieldType === 'list';
      });
      const otherItems = filter(sortedFieldTypes, (i) => {
        return i.fieldType !== 'list' && i.fieldType !== 'single';
      });

      // const listFieldTypes = concat(listItemsWithMultipleFieldTypes, listItems);
      // const singleFieldTypes = concat(singleItemsWithMultipleFieldTypes, singleItems);

      const listFieldTypes = listItemsWithMultipleFieldTypes;
      const singleFieldTypes = singleItemsWithMultipleFieldTypes;
      const otherFieldTypes = orderBy(concat(listItems, singleItems, listItems, otherItems), 'label');

      return (
        <React.Fragment>
          <div className='builder-grid builder-grid-cols-2 builder-gap-2'>
            <div className='builder-mb-4'>
              {/*<div className='builder-font-bold builder-text-primary builder-uppercase builder-mb-2'>Single fields</div>*/}
              <Container groupName='1' behaviour='copy' getChildPayload={(i) => singleFieldTypes[i]} dragHandleSelector={state.block.readonly ? 'fakeClassToPreventDragging' : ''}>
                {map(singleFieldTypes, (ft) => {
                  return (
                    <Draggable key={ft.name}>
                      <div className={`builder-flex builder-flex-row builder-items-center builder-border builder-border-gray-300 builder-my-1 builder-py-2 ${!state.block.readonly ? 'builder-cursor-pointer' : ''}`}>
                        <div className='builder-p-2 builder-flex-grow-0 builder-mr-2'>
                          <i className='far fa-grip-lines-vertical'></i>
                        </div>
                        <div className='builder-flex builder-flex-col'>
                          <div className='builder-leading-snug builder-text-md builder-font-bold'>{ft.label}</div>
                          <div className='builder-leading-snug builder-text-sm'>{`[${ft.name}]`}</div>
                        </div>
                      </div>
                    </Draggable>
                  );
                })}
              </Container>
            </div>
            <div className='builder-mb-4'>
              {/*<div className='builder-font-bold builder-text-primary builder-uppercase builder-mb-2'>List fields</div>*/}
              <Container groupName='1' behaviour='copy' getChildPayload={(i) => listFieldTypes[i]} dragHandleSelector={state.block.readonly ? 'fakeClassToPreventDragging' : ''}>
                {map(listFieldTypes, (ft) => {
                  return (
                    <Draggable key={ft.name}>
                      <div className={`builder-flex builder-flex-row builder-items-center builder-border builder-border-gray-300 builder-my-1 builder-py-2 ${!state.block.readonly ? 'builder-cursor-pointer' : ''}`}>
                        <div className='builder-p-2 builder-flex-grow-0 builder-mr-2'>
                          <i className='far fa-grip-lines-vertical'></i>
                        </div>
                        <div className='builder-flex builder-flex-col'>
                          <div className='builder-leading-snug builder-text-md builder-font-bold'>{ft.label}</div>
                          <div className='builder-leading-snug builder-text-sm'>{`[ ${ft.name} ]`}</div>
                        </div>
                      </div>
                    </Draggable>
                  );
                })}
              </Container>
            </div>
          </div>
          {otherItems && (
            <div className=''>
              <div className='builder-mb-4'>
                {/*<div className='builder-font-bold builder-text-primary builder-uppercase builder-mb-2'>Other fields</div>*/}
                <Container groupName='1' behaviour='copy' getChildPayload={(i) => otherFieldTypes[i]} dragHandleSelector={state.block.readonly ? 'fakeClassToPreventDragging' : ''}>
                  {map(otherFieldTypes, (ft) => {
                    return (
                      <Draggable key={ft.name}>
                        <div className={`builder-flex builder-flex-row builder-items-center builder-border builder-border-gray-300 builder-my-1 builder-justify-between ${!state.block.readonly ? 'builder-cursor-pointer' : ''}`}>
                          <div className='builder-p-2 builder-flex-grow-0 builder-mr-2'>
                            <i className='far fa-grip-lines-vertical'></i>
                          </div>
                          <div className='builder-p-2 builder-flex-grow builder-w-48 builder-font-bold'>{ft.label}</div>
                          <div className='builder-p-2 builder-flex-grow builder-w-96'>{`[ ${ft.name} ]`}</div>
                        </div>
                      </Draggable>
                    );
                  })}
                </Container>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return <div className='builder-bg-gray-100'>No field types found</div>;
    }
  };

  if (state.block === undefined || state.fieldTypes.length === 0) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  if (state.block !== undefined && state.fieldTypes.length > 0) {
    return (
      <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
        <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
          <div className='builder-flex builder-flex-row builder-items-center'>
            <Link to={`${routes.BLOCKS}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
              <i className='fa fa-chevron-left builder-mr-2' />
              Overview
            </Link>
            <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>{state.block.name}</div>
          </div>
          <div className='flex'>
            {state.history === undefined && (
              <ButtonWithIndicator
                loading={false}
                onClick={() => history.push(`${routes.BLOCKS}`)}
                icon='fal fa-times'
                text='Cancel'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}
            {state.history && (
              <ButtonWithIndicator
                loading={false}
                disabled={state.history.length === 1}
                onClick={() => blockUndoChangesHandler(dispatch)}
                icon='fal fa-undo'
                text='Undo changes'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}

            <ButtonWithIndicator
              loading={false}
              disabled={state.block.readonly}
              onClick={() => saveBlock(state.block)}
              icon='fal fa-save'
              text='Save'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          </div>
        </div>
        <div className='builder-flex builder-flex-row builder-p-5 builder-w-full builder-bg-white'>
          <div className='builder-flex builder-flex-col' style={{ width: '50%' }}>
            <div className='builder-text-black builder-font-bold builder-text-2xl builder-mb-2'>Field Types Library</div>
            <div className='builder-flex builder-flex-col builder-my-2'>{renderFieldTypes()}</div>
          </div>
          <div className='builder-flex builder-flex-col builder-ml-5 builder-px-4' style={{ width: '50%' }}>
            <div className='builder-text-black builder-font-bold builder-text-2xl builder-mb-2'>Definition</div>
            <div className='builder-my-2'>
              <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Name</div>
              <TextInput
                disabled={state.block.readonly}
                placeHolder='Enter unique blockName'
                className={`builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md ${formErrors['blockName'] ? 'builder-border-red-500' : 'builder-border-gray-300'}`}
                value={state.block.name}
                onChanged={(value) => {
                  blockSetNameHandler(dispatch, value);
                }}
              />
              {formErrors['blockName'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['blockName']}</span>}
            </div>
            <div className='builder-my-2'>
              <div className='builder-flex builder-items-center'>
                <input
                  type='checkbox'
                  disabled={state.block.readonly}
                  checked={state.block.isTemplate}
                  onChange={(e) => blockSetIsTemplateHandler(dispatch, !state.block.isTemplate)}
                  className={`builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md ${state.block.readonly ? '' : 'builder-cursor-pointer'}`}
                />
                <div className='builder-text-md builder-ml-2'>Is template?</div>
              </div>
            </div>

            <div className='builder-my-2'>
              <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Fields</div>
              <div className={`builder-h-full builder-border-2 builder-border-dashed builder-p-4 ${formErrors['blockFields'] ? 'builder-border-red-500' : 'builder-border-gray-400'}`}>
                {formErrors['blockFields'] && <div className='builder-mt-1 builder-mb-4 builder-text-sm builder-text-red-500'>{formErrors['blockFields']}</div>}
                <Container groupName='1' onDrop={(e) => blockAddFieldHandler(dispatch, '', e.payload.name)}>
                  {state.block.fields && state.block.fields.length < 1 && (
                    <div className='builder-text-center builder-flex builder-flex-col builder-h-24 builder-place-content-center builder-font-bold'>Drag & Drop field types from the left in this box</div>
                  )}
                  {state.block.fields &&
                    state.block.fields.length > 0 &&
                    map(state.block.fields, (field, i) => {
                      const fieldType = find(state.fieldTypes, (fieldType) => fieldType.name === field.type);
                      const fieldErrors = filter(formErrors['blockFieldsData'], { fieldId: field.id }) ? filter(formErrors['blockFieldsData'], { fieldId: field.id }) : [];

                      return (
                        <div key={i}>
                          <div className='builder-relative builder-border builder-border-gray-400 builder-my-2 builder-flex builder-flex-col builder-p-4'>
                            {!state.block.readonly && (
                              <div
                                className='builder-absolute builder-top-2 builder-right-2 builder-flex builder-items-center builder-cursor-pointer'
                                onClick={() => {
                                  blockDeleteFieldHandler(dispatch, field.id);
                                }}
                              >
                                <span>Remove</span>
                                <i className='fal fa-trash-alt builder-ml-1'></i>
                              </div>
                            )}
                            <div className='builder-text-md builder-font-bold builder-mb-2'>{fieldType ? `${fieldType?.label} [${fieldType?.name}]` : `${field.type}`}</div>
                            <div className='builder-text-md builder-font-bold builder-mb-1'>Name</div>
                            <div>
                              <TextInput
                                placeHolder='Enter unique fieldName'
                                disabled={state.block.readonly}
                                className={`builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md ${fieldErrors.length > 0 ? 'builder-border-red-500' : 'builder-border-gray-300'}`}
                                value={field.name}
                                onChanged={(value) => {
                                  blockSetFieldNameHandler(dispatch, field.id, value);
                                }}
                              />
                              {map(fieldErrors, (error) => {
                                return <div className='builder-mt-1 builder-mb-4 builder-text-sm builder-text-red-500'>{error.errorMsg}</div>;
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Container>
              </div>
            </div>
            <div className='builder-mt-6 builder-mb-2'>
              <ComponentPreview block={state.block} fieldTypes={state.fieldTypes} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Detail;

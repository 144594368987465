import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';

import TextInput from '../builder/editors/components/TextInput';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';

import WysiwygEditor from '../../components/common/WysiwygEditor';

import { cloneDeep, filter, find, isEqual, map } from 'lodash';
import { StateContext } from '../../App';
import * as uuid from 'uuid';

const customStyles = {
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const INITIAL_DATA = { en: '', nl: '', fr: '' };

const CustomGeneratedBlockInBuilderEditor = ({ isOpen, onClose, onCreate, onEdit, context, customBlock }) => {
  const globalState = useContext(StateContext);
  const [description, setDescription] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [content, setContent] = useState([]);
  const [language, setLanguage] = useState('nl');
  const languages = globalState?.configuration?.website?.languages;

  const handleHtmlChange = (value) => {
    const copyAllContent = cloneDeep(content);
    let contentForCurrentLanguage = find(copyAllContent, (c) => c.culture === language);

    if (contentForCurrentLanguage) {
      contentForCurrentLanguage.data = value;
    }

    setContent(copyAllContent);
  };

  useEffect(() => {
    if (isOpen) {
      setFormErrors({});
      if (customBlock) {
        setDescription(customBlock.description);
        setContent(customBlock.content);
      } else {
        setDescription('');
        setContent([
          { id: uuid.v4(), culture: 'nl', data: '<div>Sample Html for block NL</div>' },
          { id: uuid.v4(), culture: 'fr', data: '<div>Sample Html for block FR</div>' }
        ]);
        setLanguage('nl');
      }
    }
  }, [isOpen]);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (description === '' || description.length < 3) {
      errors['description'] = 'The name needs to be at least 3 chars long.';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const handleAddBlock = () => {
    if (isValid()) {
      if (customBlock) {
        onEdit(customBlock.name, description, content);
      } else {
        onCreate(description, content);
      }
      onClose();
    }
  };

  const contentForEditor = find(content, (c) => c.culture === language);

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} contentLabel='Custom block modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => onClose()} />
      </div>

      <div className='builder-flex builder-justify-end builder-mb-6'>
        <ButtonWithIndicator loading={false} onClick={() => handleAddBlock()} text='Save' className='builder-ml-0' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>

      <TextInput
        placeHolder='Name custom component'
        value={description}
        onChanged={(value) => setDescription(value)}
        className={`builder-border builder-rounded-md ${formErrors['description'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
      />
      {formErrors['description'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['description']}</span>}

      <div className='builder-flex builder-flex-col builder-w-200 builder-my-5'>
        <div className='builder-flex builder-mb-4'>
          <span className='builder-text-md builder-text-black builder-font-semibold'>Language:</span>
          <span className='builder-uppercase builder-text-black builder-font-semibold builder-ml-2'>{language}</span>
        </div>
        <div className='builder-flex'>
          <span className='builder-text-md builder-text-black builder-font-semibold'>Translations: </span>
          <div className='builder-flex builder-space-x-2 builder-ml-2'>
            {map(
              filter(languages, (l) => l !== language),
              (language) => {
                return (
                  <div key={language} className='builder-flex builder-items-center builder-uppercase builder-cursor-pointer' onClick={() => setLanguage(language)}>
                    {language} <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className='builder-flex'>
        <WysiwygEditor
          key={language}
          data={contentForEditor ? contentForEditor.data : ''}
          onChange={(newValue) => {
            handleHtmlChange(newValue);
          }}
        />
      </div>
      {formErrors['data'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['data']}</span>}

      {/*<div onClick={() => handleAddBlock()}> Add !</div>*/}
    </Modal>
  );
};

export default CustomGeneratedBlockInBuilderEditor;

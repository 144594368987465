import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import TextInput from '../builder/editors/components/TextInput';
import Dropdown from '../../components/common/Dropdown';
import { filter, map, orderBy, find } from 'lodash';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import { getTranslationForPage, validateStringWithSlashes } from '../../utils';
import { v4 } from 'uuid';
import { DefaultPageTypeNames } from '../../constants';
import { useHistory } from 'react-router-dom';

export const PageTypesQuery = `query PageTypes{    
  pageTypes(data: { canCreate: true }) {
    name
    path
    singleUse  
    category  
  }   
}`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%',
    height: 450
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const initialState = {
  type: DefaultPageTypeNames.custom,
  path: '',
  copyFrom: undefined,
  category: 'other'
};

const resolveInitialState = (type) => {
  if (type === undefined) {
    return initialState;
  } else {
    return {
      ...initialState,
      type: type
    };
  }
};

const PageCreateModal = ({ type, isOpen, onClose, onCreate, context }) => {
  const [state, setState] = useState(resolveInitialState(type));
  const [pageTypes, setPageTypes] = useState(undefined);
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  // Todo add fetchPageTypes loading..
  const loading = pageTypes === undefined;

  const setValue = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  useEffect(() => {
    if (isOpen) {
      fetchPageTypes();
      setState(initialState);
      setFormErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (type) {
      setState({ ...state, type: type });
    }
  }, [type]);

  // Effect to set path when type changes
  useEffect(() => {
    if (currentPageType) {
      // setState({ ...state, path: currentPageType.path });
      setState({ ...state, path: currentPageType.path, category: currentPageType.category });
    }
  }, [state.type]);

  // Effect validate path on every change
  useEffect(() => {
    isValid();
  }, [state.path]);

  const fetchPageTypes = () => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageTypesQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const pageTypes = result && result.data.pageTypes ? map(result.data.pageTypes, (pageType) => ({ name: pageType.name, path: pageType.path, singleUse: pageType.singleUse, category: pageType.category })) : [];
        setPageTypes(pageTypes);
      })
      .catch((err) => {});
  };

  const existingPages = map(context.pages, (page) => {
    return {
      value: getTranslationForPage(page, context.culture),
      key: page.id,
      type: page.type
    };
  });

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (currentPageType && !currentPageType?.singleUse) {
      if (!validateStringWithSlashes(state.path)) {
        errors['path'] = 'The path must start and end with "/"';
        isValid = false;
      }

      if (state.path === '' || state.path.length < 3) {
        errors['path'] = 'The path cannot be empty and it needs to be at least 3 chars long.';
        isValid = false;
      }
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const handleCreatePage = () => {
    if (isValid()) {
      if (state.type !== DefaultPageTypeNames.custom && state.type !== DefaultPageTypeNames.contactDetail && state.type !== DefaultPageTypeNames.blogPost && state.type !== DefaultPageTypeNames.purchase) {
        const pageType = find(pageTypes, (pt) => pt.name === state.type);
        onCreate(v4(), pageType.name, pageType.path, state.copyFrom, state.category);
      } else if (state.type === DefaultPageTypeNames.blogPost) {
        onCreate(v4(), DefaultPageTypeNames.blogPost, `/blog/${state.type}-${v4()}/`, state.copyFrom, state.category);
      } else {
        onCreate(v4(), state.type, state.path, state.copyFrom, state.category);
      }

      history.push('');
    }
  };

  const existingPagesToShow = type && filter(existingPages, (p) => p.type === type).length > 0 ? filter(existingPages, (p) => p.type === type) : existingPages;

  const currentPageType = find(pageTypes, (pt) => pt.name === state.type);

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} ontentLabel='Create page modal'>
      {loading && <div>Loading...</div>}
      {!loading && (
        <React.Fragment>
          <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
            <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => onClose()} />
          </div>

          <div style={{ width: 500 }}>
            {type === undefined && (
              <React.Fragment>
                <div className='builder-text-black builder-font-bold builder-mb-2.5'>Select a page type</div>
                <Dropdown
                  options={map(pageTypes, (pageType) => ({ key: pageType.name, value: pageType.name }))}
                  value={state.type}
                  minWidth={500}
                  placeHolder='Select a page type'
                  borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
                  selectClassName='builder-p-2 builder-rounded-md'
                  renderItem={(item) => {
                    return (
                      <div className='builder-flex builder-justify-between builder-p-2' onClick={() => setValue('type', item.key)}>
                        <span className='builder-text-sm'>{item.value}</span>
                      </div>
                    );
                  }}
                />
              </React.Fragment>
            )}

            {currentPageType && !currentPageType?.singleUse && (
              <div className='builder mt-6'>
                <div className='builder-text-black builder-font-bold builder-mb-2.5'>Default path</div>
                <TextInput placeHolder='path' value={state.path} className={`builder-border builder-rounded-md ${formErrors['path'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`} onChanged={(value) => setValue('path', value)} />
                {formErrors['path'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['path']}</span>}
              </div>
            )}

            <div className='builder mt-6'>
              <div className='builder-text-black builder-font-bold builder-mb-2.5'>Create new page from existing page(optional)</div>
              <Dropdown
                options={orderBy(existingPagesToShow, ['value'])}
                value={state.copyFrom}
                minWidth={500}
                placeHolder='Select existing page'
                borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
                selectClassName='builder-p-2 builder-rounded-md'
                renderItem={(item) => {
                  return (
                    <div className='builder-flex builder-justify-between builder-p-2' onClick={() => setValue('copyFrom', item.key)}>
                      <span className='builder-text-sm'>{item.value}</span>
                    </div>
                  );
                }}
              />
            </div>
          </div>

          <div className='builder-flex builder-justify-start builder-mt-6 builder-mb-11'>
            <ButtonWithIndicator loading={false} onClick={() => onClose()} className='builder-ml-0' text='Cancel' />

            <ButtonWithIndicator loading={false} onClick={() => handleCreatePage()} text='Create new page' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};
export default PageCreateModal;

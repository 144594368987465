import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { filter, find, indexOf, map, isEmpty } from 'lodash';

import { v4 } from 'uuid';
import cogoToast from 'cogo-toast';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { StateContext } from '../../../../App';
import { routes } from '../../../data/constants';
import { coreTypes } from 'dealer-website-components';

import { initialState, initialAdvertisementContent, reducer } from '../reducer';
import {
  advertisementInitializeHandler,
  advertisementSetHandler,
  advertisementSetDescriptionHandler,
  advertisementSetContentHandler,
  advertisementSetGroupHandler,
  advertisementSetFiltersHandler,
  advertisementUndoChangesHandler,
  advertisementFiltersModalHandler
} from '../actions';

import GroupMenu from '../../groups/components/GroupMenu';
import FiltersModal from '../../../builder/editors/FiltersModal';

import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';
import TextInput from '../../../builder/editors/components/TextInput';
import WysiwygEditor from '../../../../components/common/WysiwygEditor';

import LoadingSkeleton from './LoadingSkeleton';

const { FilterTypes } = coreTypes;

export const AdvertisementQuery = `query Advertisements($data: AdvertisementSearchInputType!) { 
  advertisements(data: $data) { 
    id
    description
    content{
      id
      data
      culture
    }
    groups
    filters
    isDraft
    disabled 
  } 
}`;

export const AdSaveMutation = `mutation AdvertisementUpdate($data: AdvertisementUpdateInputType!) {
  advertisementUpdate(data: $data) {
      ok
  }
}`;

const Detail = ({ context }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const globalState = useContext(StateContext);
  const location = useLocation();
  const history = useHistory();

  const [advertisementLanguage] = useState(location?.state?.language ? location.state.language : context.culture);
  const advertisement = state.advertisement;
  const languages = globalState?.configuration?.website?.languages;

  const [isGroupOpen, setIsGroupOpen] = useState(true);

  const { id: idFromUrl } = useParams();
  const [formErrors, setFormErrors] = useState({});

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  const fetchAd = (id) => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: AdvertisementQuery, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const ad = result && result.data && result.data.advertisements && result.data.advertisements.length > 0 ? result.data.advertisements[0] : undefined;
        if (ad) {
          advertisementSetHandler(dispatch, ad);
        } else {
          advertisementInitializeHandler(dispatch, id);
        }
      })
      .catch((err) => {});
  };

  const handleFiltersModal = () => {
    advertisementFiltersModalHandler(dispatch, { ...state.filtersModal, isOpen: !state.filtersModal.isOpen });
  };

  const handleChangeFilters = (filters) => {
    advertisementSetFiltersHandler(dispatch, filters);
  };

  const validateForm = () => {
    let isValid = false;
    let errors = {};

    // No empty values
    if (state.advertisement.description === '') {
      errors['adDescription'] = 'The advertisement description cannot be empty.';
      isValid = false;
    }

    if (isEmpty(errors)) {
      isValid = true;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const saveAd = (advertisement) => {
    if (isValid()) {
      const variables = {
        data: {
          id: advertisement.id,
          description: advertisement.description,
          content: map(advertisement.content, (c) => ({
            id: c.id,
            culture: c.culture,
            data: c.data
          })),
          groups: advertisement.groups,
          filters: JSON.stringify(advertisement.filters),
          disabled: advertisement.disabled,
          isDraft: advertisement.isDraft
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: AdSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.advertisementUpdate?.ok) {
            cogoToast.success('Advertisement Saved');
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    fetchAd(idFromUrl);
  }, []);

  if (state.advertisement === undefined || state.advertisementLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <React.Fragment>
      <div className='builder-flex builder-flex-col builder-flex-1 builder-p-5'>
        <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
          <div className='builder-flex builder-flex-row builder-items-center'>
            <Link to={`${routes.ADVERTISEMENTS}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
              <i className='fa fa-chevron-left builder-mr-2' />
              Overview
            </Link>
            <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Advertisement: {advertisement.description}</div>
          </div>
          <div className='flex'>
            {state.history === undefined && (
              <ButtonWithIndicator
                loading={false}
                onClick={() => history.push(`${routes.ADVERTISEMENTS}`)}
                icon='fal fa-times'
                text='Cancel'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}
            {state.history && (
              <ButtonWithIndicator
                loading={false}
                disabled={state.history.length === 1}
                onClick={() => advertisementUndoChangesHandler(dispatch)}
                icon='fal fa-undo'
                text='Undo changes'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}

            <ButtonWithIndicator
              loading={false}
              onClick={() => saveAd(state.advertisement)}
              icon='fal fa-save'
              text='Save'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          </div>
        </div>
        <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50'>
          <div className='builder-w-3/4 builder-mr-5'>
            <div className='builder-flex builder-flex-col builder-mb-5'>
              <div className='builder-flex builder-flex-col builder-mb-5'>
                <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Description</div>
                <TextInput
                  placeHolder='Add description'
                  className={`builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md ${formErrors['adDescription'] ? 'builder-border-red-500' : 'builder-border-gray-300'}`}
                  value={advertisement.description}
                  onChanged={(value) => {
                    advertisementSetDescriptionHandler(dispatch, value);
                  }}
                />
                {formErrors['adDescription'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['adDescription']}</span>}
              </div>
              <Tabs defaultIndex={indexOf(languages, advertisementLanguage)}>
                <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
                  {map(languages, (language) => {
                    return (
                      <React.Fragment>
                        <Tab className={tabClassNames} selectedClassName={activeTabClassNames}>
                          {language}
                        </Tab>
                      </React.Fragment>
                    );
                  })}
                </TabList>

                {map(languages, (language) => {
                  const currentAdContent = find(advertisement?.content, (content) => content.culture === language);
                  return (
                    <TabPanel className={tabPanelClassNames}>
                      <div className='builder-pt-2'>
                        <WysiwygEditor
                          data={currentAdContent?.data || initialAdvertisementContent.data}
                          onChange={(newValue) => {
                            if (currentAdContent) {
                              const otherAdContent = filter(advertisement.content, (content) => content.id !== currentAdContent.id);
                              const contentValue = [...otherAdContent, { ...currentAdContent, data: newValue }];
                              advertisementSetContentHandler(dispatch, contentValue);
                            } else {
                              const contentValue = [...advertisement.content, { ...initialAdvertisementContent, id: v4(), culture: language, data: newValue }];
                              advertisementSetContentHandler(dispatch, contentValue);
                            }
                          }}
                        />
                      </div>
                    </TabPanel>
                  );
                })}
              </Tabs>
            </div>
          </div>

          <div className='builder-w-1/4'>
            <div className={`builder-pt-2 builder-pb-4 builder-bg-white builder-mb-5 ${isGroupOpen ? 'builder-border-b builder-border-gray-300 builder-shadow-sm' : 'builder-mb-2'}`}>
              <div className='builder-flex builder-justify-between builder-items-center builder-cursor-pointer' onClick={() => setIsGroupOpen(!isGroupOpen)}>
                <h3 className='builder-text-black builder-text-md builder-font-bold builder-ml-5'>Groups</h3>
                <i className={`fas builder-mr-5 builder-text-md ${isGroupOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
              </div>
              <div className={`builder-px-5 ${isGroupOpen ? 'builder-flex builder-flex-col ' : 'builder-hidden'}`}>
                <GroupMenu
                  advertisement={advertisement}
                  onSelectGroup={(groupId) => {
                    advertisementSetGroupHandler(dispatch, [...advertisement.groups, groupId]);
                  }}
                  onDeselectGroup={(groupId) => {
                    advertisementSetGroupHandler(
                      dispatch,
                      filter(advertisement.groups, (g) => g !== groupId)
                    );
                  }}
                  context={context}
                />
              </div>
            </div>
            <div className={`builder-pt-2 builder-pb-4 builder-bg-white builder-mb-5 builder-border-b builder-border-gray-300 builder-shadow-sm'}`}>
              <div className='builder-flex builder-justify-between builder-items-center'>
                <h3 className='builder-text-black builder-text-md builder-font-bold builder-ml-5'>Filters</h3>
              </div>
              <div className={`builder-px-5 ${isGroupOpen ? 'builder-flex builder-flex-col ' : 'builder-hidden'}`}>
                <div className='mt-5 builder-cursor-pointer' onClick={() => handleFiltersModal()}>{`Edit filters (${advertisement.filters.length})`}</div>

                <FiltersModal
                  isOpen={state.filtersModal.isOpen}
                  filters={advertisement.filters ? advertisement.filters : []}
                  availableFilterTypes={[FilterTypes.makeModel, FilterTypes.label, FilterTypes.fuel]}
                  onClose={(filters) => {
                    handleChangeFilters([...filters]);
                    handleFiltersModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Detail;

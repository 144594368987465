import React from 'react';

const TYPES = {
  DANGER: 'DANGER',
  INFO: 'INFO',
  WARNING: 'WARNING'
};

const ConfirmBox = ({ onCancel, onConfirm, title, text, icon, type, dataTestId, loading }) => {
  const config = {
    [TYPES.DANGER]: {
      icon: icon ? icon : 'fal fa-exclamation-triangle',
      iconBgColor: 'builder-bg-red-100',
      iconTextColor: 'builder-text-red-400',
      confirmButtonBgColor: 'builder-bg-red-600',
      confirmButtonTextColor: 'builder-text-white'
    },
    [TYPES.INFO]: {
      icon: icon ? icon : 'fal fa-info-circle',
      iconBgColor: 'builder-bg-blue-100',
      iconTextColor: 'builder-text-blue-400',
      confirmButtonBgColor: 'builder-bg-blue-600',
      confirmButtonTextColor: 'builder-text-white'
    },
    [TYPES.WARNING]: {
      icon: icon ? icon : 'fal fa-exclamation',
      iconBgColor: 'builder-bg-yellow-200',
      iconTextColor: 'builder-text-yellow-600',
      confirmButtonBgColor: 'builder-bg-yellow-600',
      confirmButtonTextColor: 'builder-text-white'
    }
  };

  const selectedType = config[type.toUpperCase()] ? config[type.toUpperCase()] : config[TYPES.DANGER];
  return (
    <div data-testid={dataTestId} className='builder-fixed builder-inset-0 builder-overflow-y-auto' aria-labelledby='modal-title' role='dialog' aria-modal='true' style={{ zIndex: 99999999999 }}>
      <div className='builder-flex builder-items-end builder-justify-center builder-min-h-screen builder-pt-4 builder-px-4 builder-pb-20 builder-text-center sm:builder-block sm:builder-p-0'>
        <div className='builder-fixed builder-inset-0 builder-bg-gray-500 builder-bg-opacity-75 builder-transition-opacity' aria-hidden='true' />

        <span className='builder-hidden sm:builder-inline-block sm:builder-align-middle sm:builder-h-screen' aria-hidden='true'>
          &#8203;
        </span>

        <div className='builder-inline-block builder-align-bottom builder-bg-white builder-rounded-lg builder-px-4 builder-pt-5 builder-pb-4 builder-text-left builder-overflow-hidden builder-shadow-xl builder-transform builder-transition-all sm:builder-my-8 sm:builder-align-middle sm:builder-max-w-lg sm:builder-w-full sm:builder-p-6'>
          <div className='sm:builder-flex sm:builder-items-start'>
            <div className={`builder-mx-auto builder-flex-shrink-0 builder-flex builder-items-center builder-justify-center builder-h-12 builder-w-12 builder-rounded-full sm:builder-mx-0 sm:builder-h-10 sm:builder-w-10 ${selectedType.iconBgColor}`}>
              <i className={`${selectedType.icon} ${selectedType.iconTextColor}`} />
            </div>
            <div className='builder-mt-3 builder-text-center sm:builder-mt-0 sm:builder-ml-4 sm:builder-text-left'>
              <h3 className='builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900' id='modal-title'>
                {title}
              </h3>
              <div className='builder-mt-2'>
                <p className='builder-text-sm builder-text-gray-500'>{text}</p>
              </div>
            </div>
          </div>
          <div className='builder-mt-5 sm:builder-mt-4 sm:builder-ml-10 sm:builder-pl-4 sm:builder-flex'>
            <button
              type='button'
              onClick={onCancel}
              className={`builder-mt-3 builder-w-full builder-inline-flex builder-justify-center builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-bg-white builder-text-base builder-font-medium builder-text-gray-700 builder-shadow-sm sm:builder-mt-0 sm:builder-w-auto sm:builder-text-sm ${
                loading ? 'builder-pointer-events-none builder-opacity-50' : ''
              }`}
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={onConfirm}
              className={`builder-inline-flex builder-justify-center builder-w-full builder-border builder-border-transparent builder-shadow-sm builder-px-4 builder-py-2 builder-text-base builder-font-medium sm:builder-w-auto sm:builder-text-sm sm:builder-ml-3 ${
                selectedType.confirmButtonBgColor
              } ${selectedType.confirmButtonTextColor} ${loading ? 'builder-pointer-events-none builder-opacity-50' : ''}`}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmBox.defaultProps = {
  icon: '',
  title: 'Are you sure?',
  text: 'All unsaved changes will be lost.',
  type: TYPES.INFO
};

export default ConfirmBox;

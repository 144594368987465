import { useMutation, useQuery } from 'graphql-hooks';
import React, { useEffect, useContext } from 'react';
import ReactPlayer from 'react-player';
import { fileUsed, getExtension, isImage, isVideo } from '../../../utils';
import { StateContext } from '../../../App';
import { includes } from 'lodash';

export const FilesQuery = `{
    files {
      uuid,   
      file
      url
      dimensions
      megabytes
      originalFileName
    }
}`;

export const DeleteMutation = `mutation Delete($uuid: ID!) { 
        fileDelete(uuid: $uuid) { 
            ok          
        }
    }`;

const FilePicker = ({ type, onSelect, selected, gridCols, activeFilter, searchValue, triggerRefetch }) => {
  const { loading, data, refetch } = useQuery(FilesQuery);
  const [publishMutation] = useMutation(DeleteMutation);
  const state = useContext(StateContext);
  const stateJson = JSON.stringify(state.history[state.history.length - 1].pages);

  const handleRefetch = () => {
    refetch().then(() => {});
  };

  useEffect(() => {
    if (triggerRefetch > 0) {
      handleRefetch();
    }
  }, [triggerRefetch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSelect = (file) => {
    onSelect(file);
  };

  const handleDeleteFile = (file) => {
    publishMutation({
      variables: {
        uuid: file.uuid
      }
    }).then(() => {
      refetch().then(() => {});
    });
  };

  const renderItem = (file) => {
    if (isImage(file.url)) {
      return (
        <div className='builder-p-4'>
          {/* <div>
            {getExtension(file.file)} - {file.dimensions} - {file.megabytes}MB -{fileUsed(file.file, stateJson) ? 'used' : 'unused'}
          </div> */}
          <div className='builder-w-full builder-text-center builder-break-all builder-flex builder-justify-center'>
            <img
              src={file.url}
              className='builder-object-contain builder-object-center builder-mb-2'
              //style={{ width: '100%', height: 'auto' }}
              style={{ maxHeight: '280px' }}
              alt={file.uuid}
              onClick={() => handleSelect(file)}
            />
          </div>
          {/* <div className='builder-break-all'>{getFileName(file.url)}</div> */}
          <div className='builder-break-all'>{file?.originalFileName ? file?.originalFileName : '<No original filename>'}</div>
        </div>
      );
    } else if (isVideo(file.url)) {
      return (
        <div className='builder-p-4'>
          {/* <div className='builder-break-all'>{getFileName(file.url)}</div> */}
          <ReactPlayer url={file.url} playing={true} width={150} height={180} />
          <div className='builder-break-all'>{file?.originalFileName ? file?.originalFileName : '<No original filename>'}</div>
        </div>
      );
    }
  };

  const { files } = data;
  return (
    <div className={`builder-grid builder-grid-cols-${gridCols}`}>
      {files.map((file, i) => {
        const valid = type === 'image' ? isImage(file.url) : isVideo(file.url);
        if (!valid) {
          return null;
        }
        if (activeFilter && ((activeFilter === 'used' && !fileUsed(file.file, stateJson)) || (activeFilter === 'unused' && fileUsed(file.file, stateJson)))) {
          return null;
        }
        if (searchValue && searchValue !== '' && !includes(file.originalFileName, searchValue)) {
          return null;
        }

        return (
          <div
            key={i}
            className={`${selected && selected !== '' && selected === file.url ? 'builder-border builder-border-primary builder-rounded builder-shadow-md builder-m-2 builder-bg-gray-100' : 'builder-m-2 builder-bg-gray-100'}`}
            onClick={() => handleSelect(file)}
          >
            <div className='builder-flex builder-justify-between p-2'>
              <div className='builder-flex builder-flex-row builder-justify-start builder-items-center'>
                {/* <div className='builder-mr-2'>{isImage(file.url) ? 'image' : 'video'}</div> */}
                {fileUsed(file.file, stateJson) && (
                  <div className='builder-mr-2'>
                    <i className='far fa-check-circle' title='Used on website'></i>
                  </div>
                )}
                <div className='builder-bg-gray-400 builder-px-2 builder-py-1 builder-rounded builder-mr-2'>
                  <div className=' builder-text-white builder-text-xs builder-uppercase font-medium'>{getExtension(file.file)}</div>
                </div>
                {file.dimensions && <div className='builder-text-sm builder-mr-2'>{file.dimensions}</div>}
              </div>
              <div className='builder-flex builder-items-center'>
                <i
                  className='fas fa-trash-alt builder-text-red-500 builder-cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFile(file);
                  }}
                ></i>
              </div>
            </div>
            <div className='builder-flex builder-justify-center'>{renderItem(file)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default FilePicker;

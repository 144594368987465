import React, { useState } from 'react';
import { isImage } from '../../../../utils';
import FileUrlFieldEditor from '../../../builder/editors/FileUrlFieldEditor';
import cogoToast from 'cogo-toast';

const FileUploader = ({ context }) => {
  const [url, setUrl] = useState('');

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(url);
      cogoToast.success('Copied.');
    } catch (e) {
      cogoToast.error('Could not copy');
    }
  };

  return (
    <div>
      <div className='builder-w-[250px]'>
        <FileUrlFieldEditor context={context} type='image' onChange={(imageUrl) => setUrl(imageUrl)} />
      </div>
      {url && url !== '' && (
        <div className='builder-mt-5 builder-pt-5 builder-border-t builder-border-gray-400'>
          <div className='builder-flex builder-items-center builder-space-x-2'>
            <span className='builder-font-semibold'>{url}</span>
            <span className='builder-cursor-pointer hover:builder-text-gray-700' onClick={handleCopy}>
              <i className='fal fa-copy' /> Copy
            </span>
          </div>

          {url && url !== '' && isImage(url) && <img src={url} style={{ maxWidth: 500 }} alt={url} className='builder-mt-4' />}
        </div>
      )}
    </div>
  );
};
export default FileUploader;

// const FileUploader = ({ url, handleFileUpload, handleSelectExistingFile, loading, error, context }) => {
//
//   return (
//     <div>
//       <div className="builder-w-[250px]">
//         <h1>NEW</h1>
//         <FileUrlFieldEditor context={context} type="image" onChange={url => console.log(url)}/>
//       </div>
//       <div className="builder-space-y-4">
//         <h1>OLD</h1>
//         <ButtonWithIndicator loading={false}
//                              onClick={handleSelectExistingFile}
//                              icon='fas fa-images'
//                              text='Check existing'
//                              colorClass='builder-bg-white builder-text-primary'
//                              borderClass='builder-border builder-border-bg-primary'
//                              className="builder-w-[250px] builder-text-center"
//         />
//
//         <ButtonWithIndicator loading={false}
//                              onClick={handleFileUpload}
//                              icon='fal fa-plus'
//                              text='Add new'
//                              colorClass='builder-bg-white builder-text-primary'
//                              borderClass='builder-border builder-border-bg-primary'
//                              className="builder-w-[250px] builder-text-center"
//         />
//
//         {url && url !== '' && (
//           <React.Fragment>
//             <div>File url:</div>
//             <div>{url}</div>
//
//             {url && url !== '' && isImage(url) && <img src={url} style={{ maxWidth: 266, maxHeight: 200 }} alt={url} />}
//           </React.Fragment>
//         )}
//       </div>
//     </div>
//   );
// };
// export default baseFileSelector(FileUploader, 'image');

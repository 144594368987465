import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import TextInput from '../builder/editors/components/TextInput';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import { DispatchContext } from '../../App';
import { websiteCreationHandler } from '../data/actions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%'
  },
  overlay: {
    zIndex: 999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const initialState = {
  name: '',
  startFrom: undefined,
  deleteCampaign: true
};

const DraftCreateModal = ({ isOpen, onClose, onCreate, context }) => {
  const [state, setState] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useContext(DispatchContext);

  const setValue = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  useEffect(() => {
    if (isOpen) {
      setState(initialState);
      setFormErrors({});
    }
  }, [isOpen]);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (state.name === '' || state.name.length < 3) {
      errors['name'] = 'The draft name needs to be at least 3 chars long.';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const handleCreateDraft = () => {
    if (isValid()) {
      websiteCreationHandler(dispatch, state.name, state.startFrom, state.deleteCampaign);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} contentLabel='Create page modal'>
      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
          <i className='fal fa-times builder-text-xl builder-cursor-pointer' onClick={() => onClose()} />
        </div>

        <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Create a new draft website</div>

        <div className='builder-flex builder-flex-col builder-w-full'>
          <div className='builder-flex builder-flex-col builder-mb-2.5'>
            <TextInput placeHolder='Draft name' value={state.name} className={`builder-border builder-rounded-md ${formErrors['name'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`} onChanged={(value) => setValue('name', value)} />
            {formErrors['name'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['name']}</span>}
          </div>

          <div>The new draft will start from the current website.</div>

          <div className='builder-mt-6'>
            <div className='builder-flex builder-items-center'>
              <input
                type='checkbox'
                defaultChecked={state.deleteCampaign}
                onChange={(e) => setValue('deleteCampaign', e.target.checked)}
                className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md'
              />
              <label className='builder-ml-2 builder-block builder-text-sm builder-text-gray-900 builder-text-md'>Delete campaign content</label>
            </div>
          </div>

          {/*<div className="builder mt-6">*/}
          {/*    <div>Start from existing website</div>*/}
          {/*    <Dropdown options={existingWebsites}*/}
          {/*              value={state.startFrom}*/}
          {/*              placeHolder="Select website to start from"*/}
          {/*              borderClassName='builder-border-b builder-border-gray-300'*/}
          {/*              renderItem={(item) => {*/}
          {/*                  return (*/}
          {/*                      <div className="builder-flex builder-justify-between builder-p-2" onClick={() => setValue('startFrom', item.key)}>*/}
          {/*                          <span className="builder-text-sm">{item.value}</span>*/}
          {/*                      </div>*/}
          {/*                  )*/}
          {/*              }}*/}
          {/*    />*/}
          {/*</div>*/}
        </div>

        <div className='builder-flex builder-justify-start builder-mt-6'>
          <ButtonWithIndicator loading={false} className='builder-ml-0' onClick={() => onClose()} text='Cancel' />

          <ButtonWithIndicator loading={false} onClick={() => handleCreateDraft()} text='Create new draft' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
        </div>
      </div>
    </Modal>
  );
};

export default DraftCreateModal;

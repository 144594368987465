import React, { useEffect, useState } from 'react';
import DateTimePickerComp from './DateTimePickerComp';

const DateTimePickers = ({ onChange, start, stop, error }) => {
  const [startValue, setStartValue] = useState(start);
  const [stopValue, setStopValue] = useState(stop);

  useEffect(() => {
    if (startValue && stopValue) {
      onChange(startValue, stopValue);
    }
  }, [startValue, stopValue]);

  useEffect(() => {
    setStartValue(start);
    setStopValue(stop);
  }, [start, stop]);

  return (
    <div className='builder-flex builder-flex-col'>
      <div className='builder-flex builder-flex-col builder-mb-2'>
        <DateTimePickerComp onChange={(value) => setStartValue(value)} value={startValue} label='Start' error={error} />
      </div>
      <div className='builder-flex builder-flex-col'>
        <DateTimePickerComp onChange={(value) => setStopValue(value)} value={stopValue} label='Stop' error={error} />
      </div>
    </div>
  );
};

DateTimePickers.defaultProps = {
  error: undefined
};

export default DateTimePickers;

import React from 'react';

const SorterBadge = ({ sorter, onClear, dataTestId }) => {
  return (
    <div className='builder-flex builder-items-center'>
      <span>Sorted by:</span>
      <div className='builder-flex builder-items-center builder-ml-2 builder-pl-2 builder-rounded-sm builder-bg-gray-100 builder-h-[26px]'>
        <div className='builder-mr-2'>
          {sorter.label} <i className={`builder-ml-1 ${sorter.value === 'asc' ? 'fas fa-sort-alpha-down' : 'fas fa-sort-alpha-down-alt'}`} />
        </div>

        <div data-testid={dataTestId} className='builder-flex builder-items-center builder-justify-center builder-px-2 builder-h-full builder-bg-gray-200 hover:builder-bg-gray-300 builder-transition-all builder-cursor-pointer' onClick={onClear}>
          <i className='fal fa-times' />
        </div>
      </div>
    </div>
  );
};

export default SorterBadge;

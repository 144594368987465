import React from 'react';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';

const Header = ({ title, onBack, onCancel, onSave, loading, saveDisabled }) => {
  return (
    <div className='builder-flex builder-justify-between builder-p-4 builder-bg-gray-50 builder-sticky builder-top-0 builder-z-50 builder-border-b builder-border-slate-100 builder-mb-6' style={{ height: 68, minHeight: 68 }}>
      <div className='builder-flex builder-flex-1 builder-items-center'>
        {onBack && <i className='fal fa-chevron-left builder-mr-4 builder-text-gray-700 hover:text-gray-800 builder-cursor-pointer text-xl' onClick={onBack} />}
        <div className='builder-font-bold builder-text-2xl builder-text-slate-800' style={{ maxWidth: 1600 }}>
          {title}
        </div>
      </div>

      <div className='builder-flex'>
        {onCancel && (
          <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-cursor-pointer hover:builder-bg-gray-100' onClick={onCancel}>
            Cancel
          </div>
        )}

        {onSave && <ButtonWithIndicator loading={loading} onClick={onSave} icon='fal fa-save' text='Save' disabled={saveDisabled} colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-primary' />}
      </div>
    </div>
  );
};

export default Header;

import { useContext } from 'react';
import { useMutation } from 'graphql-hooks';
import { DispatchContext } from '../../App';
import { setSession, stateReset } from '../data/actions';
import { resetSessionToDefault } from '../data/session';
import { routes } from '../data/constants';
import { useHistory } from 'react-router-dom';

const SIGNOUT = `
    mutation Logout($application: Int!) {
        logout(application: $application)  {
           ok          
        }
    }`;

export function useSignOut() {
  const [signoutMutation] = useMutation(SIGNOUT);
  const dispatch = useContext(DispatchContext);
  let history = useHistory();

  const handleSignOut = () => {
    return signoutMutation({
      variables: {
        application: 0
      }
    }).then((result) => {
      setSession(dispatch, resetSessionToDefault());
      stateReset(dispatch);
      history.push(routes.LOGIN);
    });
  };

  return {
    signOut: () => {
      return handleSignOut();
    }
  };
}

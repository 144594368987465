import React from 'react';
import App from './App';
import './css/app.css';
import 'react-toggle/style.css';
import { ClientContext } from 'graphql-hooks';
import { client } from './graphqlClient';
import { createRoot } from 'react-dom/client';

// export const client = new GraphQLClient({
//   url: '/graphql',
//   credentials: 'same-origin',
//   headers: { 'Accept-Language': 'en', Environment: 0 },
//   onError: ({ operation, result }) => {
//     if (result.error && result.error.graphQLErrors) {
//       const error = result.error.graphQLErrors[0];
//       if (error.code === '403') {
//         resetSessionToDefault();
//         window.location.reload();
//       }
//     }
//   }
// });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ClientContext.Provider value={client}>
    <App />
  </ClientContext.Provider>
);

import React from 'react';

const DistributeContentTo = ({ onClick }) => {
  return (
    <div className='builder-flex builder-items-center builder-px-4 builder-py-2 builder-border builder-border-gray-200 builder-shadow-sm builder-text-gray-800 builder-cursor-pointer' onClick={onClick}>
      <i className='fal fa-chart-network  builder-text-gray-800 builder builder-mr-2' /> Copy to all
    </div>
  );
};

export default DistributeContentTo;

import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomBlock } from '../hooks/useCustomBlock';
import { StateContext } from '../../../../App';
import { find } from 'lodash';
import Header from './Header';
import LoadingSkeleton from './LoadingSkeleton';
import CustomBlockForm from './CustomBlockForm';

const Details = ({ context }) => {
  const contextState = useContext(StateContext);
  const globalState = useContext(StateContext);
  const languages = globalState?.configuration?.website?.languages;
  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);
  const { id: idFromUrl } = useParams();
  const { fetchData, initialize, fetching, saving, onSave, onUpdateDescription, onUpdateContent, state } = useCustomBlock();

  useEffect(() => {
    if (currentWebsite) {
      if (idFromUrl) {
        fetchData(idFromUrl, currentWebsite.id);
      } else {
        initialize(languages);
      }
    }
  }, [currentWebsite, idFromUrl]);

  if (!currentWebsite || fetching) {
    return <LoadingSkeleton />;
  }

  return (
    <div data-testid='custom-block-detail-container' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative'>
      <Header onSave={() => onSave(state.customBlock, currentWebsite?.id)} loading={saving || fetching} context={context} />
      <CustomBlockForm context={context} customBlock={state.customBlock} formErrors={state.formErrors} onUpdateDescription={onUpdateDescription} onUpdateContent={onUpdateContent} />
    </div>
  );
};

export default Details;

import React, { useEffect, useReducer, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/common/PrivateRoute';
import { generalSettingsRoutes, routes } from './features/data/constants';
import { initialState, MODAL_TYPES, rootReducer } from './features/data/reducers';

import { initSession, updateSession } from './features/data/session';
import {
  customBlockCreationHandler,
  customBlockEditHandler,
  customBlockSelectHandler,
  dataSetHandler,
  modalCloseHandler,
  pageCreationHandler,
  pageSelectHandler,
  publishLogAddHandler,
  sessionTakeOverMessageSetHandler,
  setSession
} from './features/data/actions';
import Login from './features/authentication/Login';
import Page404 from './components/Page404';
import Dealership from './features/settings/dealership/Dealership';
import Dealerships from './features/settings/dealership/Dealerships';
import { initializePusher } from './pusher';
import { useManualQuery } from 'graphql-hooks';
import { DataQuery } from './features/data/graphql';
import PageCreateModal from './features/pages/PageCreateModal';
import { LoadingIndicator } from './components/common';
import DraftCreateModal from './features/drafts/DraftCreateModal';
import { filter, find, head, map, values } from 'lodash';
import CustomGeneratedBlockInBuilderEditor from './features/custom-blocks/CustomGeneratedBlockInBuilderEditor';
import PublishModal from './features/builder/components/PublishModal';
import PublishDraftToProductionModal from './features/drafts/PublishDraftToProductionModal';
import Builder from './features/builder/Builder';
import SessionRestrictionModal, { SessionRestrictionModalViews } from './features/session/SessionRestrictionModal';
import { useClientMessages } from './features/session/useClientMessages';
import { useSignOut } from './features/authentication/useSignout';
import Template from './components/Template';
import { default as CampaignsOverview } from './features/campaigns/overview/components/Overview';
import { default as CampaignDetail } from './features/campaigns/detail/components/Detail';
import { default as PagesOverview } from './features/pages/Overview';
import { default as BlogOverview } from './features/blog/Overview';
import { default as AdvertisementsOverview } from './features/advertisements/overview/components/Overview';
import { default as AdvertisementDetail } from './features/advertisements/detail/components/Detail';
import { default as BlocksOverview } from './features/blocks/Overview';
import { default as BlocksDetail } from './features/blocks/Detail';
import { default as VehicleFiltersOverview } from './features/filters/overview/components/Overview';
import { default as VehicleFiltersDetail } from './features/filters/detail/components/Detail';
import { default as FaqOverview } from './features/faq/overview/components/Overview';
import { default as TopicsOverview } from './features/faq/topics/overview/components/Overview';
import { default as FaqCategoryDetail } from './features/faq/categories/detail/components/Detail';
import { default as FaqQuestionGroupDetail } from './features/faq/question-groups/detail/components/Detail';
import { default as FaqQuestionDetail } from './features/faq/questions/detail/components/Detail';
import { default as FaqTopicDetail } from './features/faq/topics/detail/components/Detail';
import { default as CustomBlocksOverview } from './features/custom-blocks/overview/components/Overview';
import { default as CustomBlockDetails } from './features/custom-blocks/details/components/Details';
import { client } from './graphqlClient';
import { DefaultPageTypeNames } from './constants';
import GeneralSettings from './features/settings/general/GeneralSettings';
export const StateContext = React.createContext();
export const DispatchContext = React.createContext();

function App() {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [currentLanguage, setCurrentLanguage] = useState(undefined);
  const [fetchData, { loading: initialLoading, error: loadingError }] = useManualQuery(DataQuery);
  const currentState = state.history[state.history.length - 1];
  const { clientMessageTypes, userChannel } = useClientMessages();
  const [lastReload, setLastReload] = useState(undefined);
  const { signOut } = useSignOut();

  useEffect(() => {
    if (currentLanguage === undefined) {
      const currentState = state.history[state.history.length - 1];
      if (currentState && currentState.languages.length > 0) {
        const language = head(filter(currentState.languages, (l) => l.available));
        setCurrentLanguage(language.code);
      }
    }
  }, [state]);

  useEffect(() => {
    if (userChannel) {
      userChannel.bind('publish_output', function (data) {
        publishLogAddHandler(dispatch, data.message);
      });
    }
  }, [userChannel]);

  const domainName = () => {
    if (state && state.configuration) {
      let domainName = head(state.configuration.website.domains);
      if (domainName) {
        return domainName;
        //     } else {
        //         if (window) {
        //             try {
        //                 return `${window.location.origin.replace('builder.', '')}`
        //             } catch (e) {
        //                 return ''
        //             }
        //         }
      }
    }

    return '';
  };

  const website = find(state.websites, (w) => w.id === state.currentWebsite);

  const context = {
    culture: currentLanguage,
    builder: true,
    multiLanguage: false,
    mapBoxToken: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
    pages: currentState.pages,
    // urls: DefaultPageNames,
    customBlocks: currentState.customBlocks,
    campaigns: currentState.campaigns,
    domainName: domainName(),
    // defaultPageNames: { ...DefaultPageNames },
    defaultPageNames: { ...DefaultPageTypeNames },
    environment: website && !website.isProduction ? 1 : 0
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    // window.addEventListener('unload', handleEndSession)
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      // window.removeEventListener('unload', handleEndSession)
      // handleEndSession()
    };
  }, [state.session]);

  const alertUser = async (e) => {
    e.preventDefault();

    if (state.session.authenticated && window.location.hostname !== 'localhost') {
      await signOut();
      // e.returnValue = '';
    }
  };

  const handleReloadPage = () => {
    setLastReload(new Date());
    handleFetchWebsiteData();
  };

  useEffect(() => {
    if (!state.contentTypeDefinitions && state.session && state.session.authenticated) {
      handleFetchWebsiteData();
    }
  }, [state.contentTypeDefinitions, state.session]);

  useEffect(() => {
    // Set home page selected initial
    if (!initialLoading && state.session && state.session.authenticated) {
      if (values(currentState.pages).length > 1) {
        const homeId = head(filter(Object.keys(currentState.pages), (key) => currentState.pages[key].type === 'home'));
        if (homeId) {
          pageSelectHandler(dispatch, homeId);
        }
      }
    }
  }, [initialLoading, state.websites, state.session]);

  const handleFetchWebsiteData = (websiteId = undefined) => {
    const options = {};
    if (websiteId) {
      options['variables'] = { websiteId: websiteId };
    }

    const website = find(state.websites, (w) => w.id === websiteId);
    client.setHeader('Environment', !website || (website && website.isProduction) ? '0' : '1');
    fetchData(options).then((result) => {
      dataSetHandler(dispatch, result.data);
    });
  };

  useEffect(() => {
    async function initializeSession() {
      const session = await initSession();
      setSession(dispatch, session);
    }

    initializeSession();
  }, [lastReload]);

  useEffect(() => {
    if (state.configuration && state.session && state.session.user) {
      initializePusher(state.configuration.company_code, state.session.sessionId);
    }
  }, [state.configuration, state.session]);

  useEffect(() => {
    if (userChannel) {
      userChannel.bind('take_over_request', function (data) {
        switch (data.type) {
          case clientMessageTypes.take_over_request:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.TakeOverRequest);
            break;
          case clientMessageTypes.take_over_request_accepted:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.TakeOverRequestAccepted);
            break;
          case clientMessageTypes.take_over_request_declined:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.TakeOverRequestDeclined);
            break;
          case clientMessageTypes.force_sign_out:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.ForceSignOut);
            break;
          default:
            break;
        }
      });
    }
  }, [userChannel]);

  const handleAuthenticated = (user, sessionId, otherSessionsCount) => {
    const updatedSession = updateSession(state.session, user, sessionId, otherSessionsCount);
    setSession(dispatch, updatedSession);
  };

  const handleCloseModal = (type) => {
    modalCloseHandler(dispatch, type);
  };

  const handleAddPage = (id, type, path, copyFrom, category) => {
    const existingPage = find(values(currentState.pages), (page) => page.url === path);

    if (!existingPage) {
      pageCreationHandler(dispatch, id, type, path, copyFrom, category);
      pageSelectHandler(dispatch, id);
    } else {
      pageSelectHandler(dispatch, existingPage.id);
    }
  };

  const handleAddCustomBlock = (description, content) => {
    customBlockCreationHandler(dispatch, description, content);
  };

  const handleEditCustomBlock = (name, description, content) => {
    customBlockEditHandler(dispatch, name, description, content);
  };

  if (loadingError) {
    return (
      <div className='builder-rounded-md builder-bg-red-50 builder-p-4 builder-m-10'>
        <div className='builder-text-md builder-font-semibold builder-font-medium builder-text-red-800'>Error loading website. Please contact administrator.</div>
      </div>
    );
  }

  if (initialLoading) {
    return <LoadingIndicator />;
  }

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <Router>
          <React.Fragment>
            {/*ROUTES*/}
            <Switch>
              <Route path={routes.LOGIN}>
                <Login onAuthenticated={handleAuthenticated} />
              </Route>
              <PrivateRoute exact path={routes.HOME}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <Builder context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.PAGES}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <PagesOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.BLOG}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlogOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.CAMPAIGNS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CampaignsOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.CAMPAIGN_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CampaignDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.ADVERTISEMENTS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <AdvertisementsOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.ADVERTISEMENT_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <AdvertisementDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_OVERVIEW}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_TOPICS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <TopicsOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_TOPIC_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqTopicDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_CATEGORY_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqCategoryDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.FAQ_QUESTION_GROUP_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqQuestionGroupDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.FAQ_QUESTION_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqQuestionDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.CUSTOM_BLOCKS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CustomBlocksOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.CUSTOM_BLOCK}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CustomBlockDetails context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.BLOCKS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlocksOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.BLOCKS_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlocksDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.VEHICLE_FILTERS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <VehicleFiltersOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.VEHICLE_FILTERS_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <VehicleFiltersDetail context={context} />
                </Template>
              </PrivateRoute>

              {map(generalSettingsRoutes, (path) => (
                <PrivateRoute key={path} exact path={path}>
                  <GeneralSettings context={context} />
                </PrivateRoute>
              ))}

              <PrivateRoute path={`${routes.SETTINGS_DEALERSHIPS}`}>
                <Dealerships context={context} />
              </PrivateRoute>
              <PrivateRoute path={`${routes.SETTINGS_DEALERSHIP}/:id`}>
                <Dealership context={context} />
              </PrivateRoute>

              <Route path='*'>
                <Page404 />
              </Route>
            </Switch>

            {/*MODALS*/}
            <DraftCreateModal
              isOpen={state.modal && state.modal.type === MODAL_TYPES.DRAFT_CREATE}
              onCreate={(type, name, copyFrom) => {
                handleCloseModal(MODAL_TYPES.DRAFT_CREATE);
              }}
              onClose={() => handleCloseModal(MODAL_TYPES.DRAFT_CREATE)}
              context={context}
            />

            <PageCreateModal
              isOpen={state.modal && state.modal.type === MODAL_TYPES.PAGE_CREATE}
              type={state.modal && state.modal.payload.type ? state.modal.payload.type : undefined}
              onCreate={(id, type, path, copyFrom, category) => {
                handleAddPage(id, type, path, copyFrom, category);
                handleCloseModal(MODAL_TYPES.PAGE_CREATE);
              }}
              onClose={() => handleCloseModal(MODAL_TYPES.PAGE_CREATE)}
              context={context}
            />

            {/* <CampaignCreateModal
              isOpen={state.modal && state.modal.type === MODAL_TYPES.CAMPAIGN_CREATE}
              onClose={() => {
                campaignSelectHandler(dispatch, undefined);
                handleCloseModal(MODAL_TYPES.CAMPAIGN_CREATE);
              }}
              campaign={find(currentState.campaigns, (c) => c.id === currentState.campaignSelected)}
              context={context}
            /> */}

            <CustomGeneratedBlockInBuilderEditor
              isOpen={state.modal?.type === MODAL_TYPES.BLOCK_CREATE}
              onClose={() => {
                customBlockSelectHandler(dispatch, undefined);
                handleCloseModal(MODAL_TYPES.BLOCK_CREATE);
              }}
              languages={currentState.languages}
              context={context}
              customBlock={find(currentState.customBlocks, (cb) => cb.name === currentState.customBlockSelected)}
              onCreate={(description, content) => handleAddCustomBlock(description, content)}
              onEdit={(name, description, content) => handleEditCustomBlock(name, description, content)}
            />

            <PublishModal isOpen={state.modal && state.modal.type === MODAL_TYPES.PUBLISH_WEBSITE} context={context} />

            <PublishDraftToProductionModal isOpen={state.modal && state.modal.type === MODAL_TYPES.PUBLISH_DRAFT_TO_PRODUCTION} context={context} onReload={() => handleReloadPage()} />

            <SessionRestrictionModal view={state.sessionRestrictionModalView} onReload={() => handleReloadPage()} context={context} />
          </React.Fragment>
        </Router>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

export default App;

import { useState } from 'react';
import { useMutation } from 'graphql-hooks';

export const FileUploadMutation = `mutation FileUpload($file: Upload!) { 
        fileUpload(file: $file) { 
            ok 
            uuid
            location
            url            
        }
    }`;

export function useFileUpload() {
  const [url, setUrl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mutation] = useMutation(FileUploadMutation);

  const handleFileUpload = (file, onComplete = undefined) => {
    setLoading(true);

    mutation({ variables: { file: file } })
      .then((res) => {
        const { ok, url } = res.data.fileUpload;
        if (ok) {
          setUrl(url);

          if (onComplete) onComplete(url);
        } else {
          setError('Error uploading file');
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError('Error uploading file');
      });
  };

  return {
    uploadFile: (file, onComplete = undefined) => handleFileUpload(file, onComplete),
    url: url,
    loading: loading,
    error
  };
}

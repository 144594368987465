import React, { useEffect, useReducer, useState } from 'react';
import { initialState, reducer } from '../reducer';

import cogoToast from 'cogo-toast';
import { dataResetLoadingHandler, dataSetHandler, dataSetLoadingHandler, pageTypeAddHandler, pageTypeDeletedHandler } from '../actions';
import { filter, includes, map, orderBy } from 'lodash';
import LoadingSkeleton from './LoadingSkeleton';
import ConfirmBox from '../../../../../../components/common/ConfirmBox';
import Header from './Header';
import AddEditRow from '../../detail/components/AddEditRow';
import NoResults from './NoResults';
import { resolveSorter } from '../../../../utils';

export const PageTypesDataQuery = `
  query {
    pageTypes {
        id
        isDraft
        disabled
        immutable
        isTemplate
        matchPath
        name
        path
        singleUse
        category
    }    
  }
`;

export const PageTypeDeleteMutation = `
  mutation PageTypeDelete($data: PageTypeDeleteInputType!) {
    pageTypeDelete(data: $data) {
        ok
    }
  }
`;

const sorters = {
  name: { key: 'name', sortType: 'desc', label: 'Name' },
  path: { key: 'path', sortType: 'desc', label: 'Path' },
  matchPath: { key: 'matchPath', sortType: 'desc', label: 'Match path' }
};

const Overview = ({ context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pageTypeToDelete, setPageTypeToDelete] = useState(null);
  const [pageTypeDeletingId, setPageTypeDeletingId] = useState(null);
  const [pageTypeToEdit, setPageTypeToEdit] = useState(null);
  const [pageTypeToAdd, setPageTypeToAdd] = useState(null);
  const [showOnlyEditable, setShowOnlyEditable] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sorter, setSorter] = useState(null);

  const fetchData = () => {
    dispatch(dataSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageTypesDataQuery, variables: { data: { excludeDisabled: false } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          dispatch(dataSetHandler(data?.pageTypes));
        } else {
          cogoToast.error('No data returned from the server.');
        }

        dispatch(dataResetLoadingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(dataResetLoadingHandler());
      });
  };

  const handleDelete = (pageType) => {
    const id = pageType.id;
    setPageTypeDeletingId(id);
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageTypeDeleteMutation, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageTypeDelete?.ok) {
          dispatch(pageTypeDeletedHandler(id));
          cogoToast.success('Page type deleted');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setPageTypeDeletingId(null);
      })
      .catch((err) => {
        cogoToast.error(err);
        setPageTypeDeletingId(null);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onEdit = (pageType) => {
    setPageTypeToEdit(pageType);
  };

  const onDelete = (pageType) => {
    setPageTypeToDelete(pageType);
  };

  const onAdd = () => {
    setPageTypeToAdd({});
  };

  const onCancelAdd = () => {
    setPageTypeToAdd(null);
  };

  const onAddSorter = (addedSorter) => {
    const newSorter = resolveSorter(sorter, addedSorter);
    setSorter(newSorter);
  };

  if (state.loading) {
    return <LoadingSkeleton />;
  }

  const getRowClasses = (canBeChanged, isDeleting, editingActive) => {
    let classes = '';
    if (!canBeChanged) classes = 'builder-bg-gray-100';
    if (isDeleting) classes = 'builder-bg-gray-200 builder-transition-all builder-animate-pulse';
    if (editingActive) classes = 'builder-bg-opacity-25 builder-opacity-25';
    return `builder-flex ${classes}`;
  };

  // Apply only editable filter
  let pageTypes = showOnlyEditable
    ? orderBy(
        filter(state.pageTypes, (pageType) => !pageType.immutable),
        'name'
      )
    : orderBy(state.pageTypes, 'name');
  // Apply search filter
  pageTypes = searchTerm && searchTerm !== '' ? filter(pageTypes, (pt) => includes(pt.name, searchTerm) || includes(pt.path, searchTerm) || includes(pt.matchPath, searchTerm)) : pageTypes;
  // Apply sort filter
  pageTypes = sorter ? orderBy(pageTypes, [sorter.key], [sorter.sortType]) : pageTypes;

  return (
    <div>
      {pageTypeToDelete !== null && (
        <ConfirmBox
          dataTestId='pageType-confirm-delete'
          title='Delete page type'
          text={`Are you sure you want to delete page type '${pageTypeToDelete.name}'?`}
          type='DANGER'
          onCancel={() => setPageTypeToDelete(null)}
          onConfirm={() => {
            handleDelete(pageTypeToDelete);
            setPageTypeToDelete(null);
          }}
        />
      )}

      <div className='builder-mb-4'>
        <Header onAdd={onAdd} sorter={sorter} onClearSorter={() => setSorter(null)} showOnlyEditable={showOnlyEditable} onToggleOnlyEditable={(value) => setShowOnlyEditable(value)} onSearch={(value) => setSearchTerm(value)} context={context} />
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <div className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <div className='builder-flex builder-items-center builder-bg-gray-50'>
                  <div
                    className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'
                    onClick={() => onAddSorter(sorters.name)}
                    style={{ width: '25%' }}
                  >
                    Name
                    {sorter && sorter?.key === sorters?.name?.key ? <i className={`fas ${sorter?.sortType === 'asc' ? 'fa-caret-down' : 'fa-caret-up'} builder-ml-2`} /> : <i className='fal fa-minus builder-ml-2 builder-opacity-50 text-[10px]' />}
                  </div>
                  <div
                    className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'
                    onClick={() => onAddSorter(sorters.path)}
                    style={{ width: '20%' }}
                  >
                    Path
                    {sorter && sorter?.key === sorters?.path?.key ? <i className={`fas ${sorter?.sortType === 'asc' ? 'fa-caret-down' : 'fa-caret-up'} builder-ml-2`} /> : <i className='fal fa-minus builder-ml-2 builder-opacity-50 text-[10px]' />}
                  </div>
                  <div
                    className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'
                    onClick={() => onAddSorter(sorters.matchPath)}
                    style={{ width: '25%' }}
                  >
                    Match path
                    {sorter && sorter?.key === sorters?.matchPath?.key ? (
                      <i className={`fas ${sorter?.sortType === 'asc' ? 'fa-caret-down' : 'fa-caret-up'} builder-ml-2`} />
                    ) : (
                      <i className='fal fa-minus builder-ml-2 builder-opacity-50 text-[10px]' />
                    )}
                  </div>
                  <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                    State
                  </div>
                  <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                    Actions
                  </div>
                </div>
                <div className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {/*If add form is visible. Show if in the first row*/}
                  {pageTypeToAdd && (
                    <AddEditRow
                      onCancel={onCancelAdd}
                      handlePageTypesUpdate={(pageType) => {
                        dispatch(pageTypeAddHandler(pageType));
                        setPageTypeToAdd(null);
                      }}
                      context={context}
                    />
                  )}
                  {/*********************/}

                  {/*No results to show*/}
                  {pageTypes && pageTypes.length < 1 && (
                    <div className='builder-w-full builder-text-center'>
                      <NoResults icon='far fa-info-circle' text='There are no page types to show.' />
                    </div>
                  )}
                  {/*********************/}

                  {pageTypes &&
                    pageTypes.length > 0 &&
                    map(pageTypes, (pageType) => {
                      // Return edit form in a row
                      if (pageTypeToEdit && pageTypeToEdit?.id === pageType.id) {
                        return (
                          <AddEditRow
                            key={pageType.id}
                            id={pageType.id}
                            isDraft={pageType.isDraft}
                            disabled={pageType.disabled}
                            immutable={pageType.immutable}
                            isTemplate={pageType.isTemplate}
                            matchPath={pageType.matchPath}
                            name={pageType.name}
                            path={pageType.path}
                            category={pageType.category}
                            singleUse={pageType.singleUse}
                            onCancel={() => setPageTypeToEdit(null)}
                            handlePageTypesUpdate={(pageType) => {
                              dispatch(pageTypeAddHandler(pageType));
                              setPageTypeToEdit(null);
                            }}
                            context={context}
                          />
                        );
                      }

                      // Return data
                      const canBeChanged = !pageType.immutable;
                      const isEditOrAddActive = pageTypeToEdit || pageTypeToAdd;
                      const isDeleting = pageTypeDeletingId === pageType.id;
                      const rowClasses = getRowClasses(canBeChanged, isDeleting, isEditOrAddActive);

                      return (
                        <div key={pageType.id} className={rowClasses}>
                          <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-font-bold builder-text-black' style={{ width: '25%' }}>
                            {pageType.name}
                          </div>
                          <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '20%' }}>
                            {pageType.path}
                          </div>
                          <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '25%' }}>
                            {pageType.matchPath && pageType.matchPath !== 'null' ? pageType.matchPath : <i className='fad fa-minus' />}
                          </div>
                          <div className='builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            <div className='builder-flex builder-items-center builder-space-x-2'>
                              <div style={{ width: 80, minWidth: 80 }}>Disabled</div>
                              {pageType.disabled ? <i className='far fa-check builder-mt-0.5' /> : <i className='far fa-times builder-mt-0.5'></i>}
                            </div>
                            <div className='builder-flex builder-items-center  builder-space-x-2'>
                              <div style={{ width: 80, minWidth: 80 }}>Single use</div>
                              {pageType.singleUse ? <i className='far fa-check builder-mt-0.5' /> : <i className='far fa-times builder-mt-0.5'></i>}
                            </div>
                            <div className='builder-flex builder-items-center builder-space-x-2'>
                              <div style={{ width: 80, minWidth: 80 }}>Is template</div>
                              {pageType.isTemplate ? <i className='far fa-check builder-mt-0.5' /> : <i className='far fa-times builder-mt-0.5'></i>}
                            </div>
                          </div>

                          <div className='builder-flex builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md' style={{ width: '15%' }}>
                            {canBeChanged ? (
                              <React.Fragment>
                                <div
                                  data-testid='pageType-edit-button'
                                  className={`builder-flex builder-items-center builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3 builder-text-black builder-transition-all ${
                                    canBeChanged ? 'builder-cursor-pointer hover:builder-text-gray-800' : ''
                                  }`}
                                  onClick={canBeChanged ? () => onEdit(pageType) : undefined}
                                >
                                  Edit <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                                <div
                                  data-testid='pageType-delete-button'
                                  className={`builder-flex builder-items-center builder-text-black builder-transition-all ${canBeChanged ? 'builder-cursor-pointer hover:builder-text-gray-800' : ''}`}
                                  onClick={canBeChanged ? () => onDelete(pageType) : undefined}
                                >
                                  {isDeleting ? 'Deleting...' : 'Delete'}
                                  <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              </React.Fragment>
                            ) : (
                              <i className='fad fa-minus' />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

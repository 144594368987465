import { GraphQLClient } from 'graphql-hooks';
import { resetSessionToDefault } from './features/data/session';

export const client = new GraphQLClient({
  url: '/graphql',
  credentials: 'same-origin',
  headers: { 'Accept-Language': 'en', Environment: 0 },
  onError: ({ operation, result }) => {
    if (result.error && result.error.graphQLErrors) {
      const error = result.error.graphQLErrors[0];
      if (error.code === '403') {
        resetSessionToDefault();
        window.location.reload();
      }
    }
  }
});

import React, { useEffect, useState } from 'react';
import Header from './Header';
import { routes } from '../../../data/constants';
import cogoToast from 'cogo-toast';
import { useMutation, useQuery } from 'graphql-hooks';
import { ConfigurationQuery, ConfigurationSettingUpdateMutation, SettingsUpdateMutation } from '../../../data/graphql';
import ConfigurationEditor from './ConfigurationEditor';
import { useHistory } from 'react-router-dom';
import { ErrorIndicator, LoadingIndicator } from '../../../../components/common';
import ConfirmBox from '../../../../components/common/ConfirmBox';

const Configuration = ({ isSuperUser, currentState, context }) => {
  const [configuration, setConfiguration] = useState(undefined);
  const [initialConfiguration, setInitialConfiguration] = useState(undefined);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [saveMutation, { loading: saving }] = useMutation(ConfigurationSettingUpdateMutation);
  const { loading, error, data } = useQuery(ConfigurationQuery);
  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      setConfiguration(data.configuration);
      setInitialConfiguration(data.configuration);
      setNeedsConfirmation(false);
    }
  }, [loading, data]);

  if ((loading && !error) || !configuration) {
    return <LoadingIndicator />;
  }

  if (!loading && error) {
    return <ErrorIndicator error='Configuration could not be fetched.' />;
  }

  const hasChanges = configuration !== initialConfiguration;

  const handleCancel = () => {
    if (hasChanges) {
      setNeedsConfirmation(true);
    } else {
      history.push(routes.HOME);
    }
  };

  const handleConfigurationSave = () => {
    saveMutation({
      variables: {
        value: configuration
      }
    })
      .then((res) => {
        const { ok } = res.data.configurationSettingUpdate;
        if (ok) {
          cogoToast.success('Settings saved!');

          setInitialConfiguration(configuration);
          setNeedsConfirmation(false);
        }
      })
      .catch((error) => {
        cogoToast.error('Error saving settings!');
      });
  };

  return (
    <React.Fragment>
      {needsConfirmation && (
        <ConfirmBox
          title='Are you sure?'
          text='You have unsaved changes. If you proceed they will be lost.'
          onCancel={() => setNeedsConfirmation(false)}
          onConfirm={() => {
            history.push(routes.HOME);
          }}
        />
      )}
      <Header onCancel={handleCancel} onSave={handleConfigurationSave} loading={loading} saveDisabled={!hasChanges || saving} />
      <ConfigurationEditor configuration={configuration} isSuperUser={isSuperUser} onChange={(updatedSettings) => setConfiguration(updatedSettings)} />
    </React.Fragment>
  );
};

export default Configuration;

import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useSignOut } from '../../authentication/useSignout';
import { useClientMessages } from '../useClientMessages';

const TakeOverRequestWaiting = ({ onReload }) => {
  const { signOut } = useSignOut();
  const { sendForceSignOut } = useClientMessages();

  return (
    <div className='builder-text-black'>
      <h3 className='builder-text-center'>Waiting for response</h3>

      <div className='builder-flex builder-justify-center builder-py-6 ' style={{ fontSize: 16 }}>
        <CountdownCircleTimer
          isPlaying
          duration={120}
          colors={[
            ['#004777', 0.33],
            ['#F7B801', 0.33],
            ['#A30000', 0.33]
          ]}
          size={120}
          onComplete={() => {
            sendForceSignOut();
          }}
        >
          {({ remainingTime }) => {
            return (
              <div className='builder-flex builder-flex-col builder-items-center'>
                {/*<div className="time">{Math.ceil(remainingTime / 60)}</div>*/}
                {/*<div>minutes</div>*/}
                <div className='time'>{Math.ceil(remainingTime)}</div>
                <div>seconds</div>
              </div>
            );
          }}
        </CountdownCircleTimer>
      </div>

      <div className='builder-text-center builder-mt-4'>
        I don't want to wait,{' '}
        <span className='builder-underline' onClick={() => signOut()}>
          logout
        </span>
      </div>
    </div>
  );
};

export default TakeOverRequestWaiting;

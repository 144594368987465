import React, { useEffect, useState } from 'react';
import DateTimePickers from './DateTimePickers';
import TextArea from '../../builder/editors/components/TextArea';

const DaysWithDescription = ({ item, onChanged }) => {
  const [error, setError] = useState('');
  const [start, setStart] = useState('');
  const [stop, setStop] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (item) {
      setStart(item.start);
      setStop(item.stop);
      setDescription(item.description);
    }
  }, [item]);

  useEffect(() => {
    if (start && stop && description) {
      setError('');
      onChanged(start, stop, description);
    }
  }, [start, stop, description]);

  return (
    <div>
      <DateTimePickers
        error={error}
        onChange={(start, stop) => {
          setStart(start);
          setStop(stop);
        }}
        start={start}
        stop={stop}
      />

      {/*<textarea*/}
      {/*    className="builder-shadow builder-appearance-none builder-border builder-border-gray-300 builder-rounded builder-w-full builder-py-2 builder-px-3 builder-text-gray-700 builder-leading-tight  focus:builder-outline-none builder-mt-2"*/}
      {/*    rows="2"*/}
      {/*    placeholder="Add some description"*/}
      {/*    value={description}*/}
      {/*    onChange={(e) => !start || !stop || e.target.value.length > 0 ? setDescription(e.target.value) : undefined}*/}

      {/*/>*/}

      {error && <div className='builder-text-xs builder-text-red-500 builder-mt-2'>{error}</div>}
      <TextArea value={description} className='builder-mt-2' placeHolder='Add some description' onChanged={(value) => setDescription(value.length > 0 ? value : 'Wij zijn gesloten')} />
    </div>
  );
};

export default DaysWithDescription;

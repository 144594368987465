import React, { useEffect, useRef, useState } from 'react';

const TextArea = ({ value, placeHolder, onChanged, className, rows }) => {
  const inputRef = useRef(null);
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <textarea
      className={`builder-shadow builder-appearance-none builder-border builder-border-gray-300 builder-rounded builder-w-full builder-py-2 builder-px-3 builder-text-gray-700 builder-leading-tight  focus:builder-outline-none ${className}`}
      ref={inputRef}
      rows={rows}
      placeholder={placeHolder}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onBlur={() => {
        onChanged(text);
      }}
      onFocus={() => {
        inputRef.current.focus();
        inputRef.current.select();
      }}
    />
  );
};

export default TextArea;

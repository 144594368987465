import React from 'react';
import SelectBox from './SelectBox';
import { map, includes, filter, orderBy } from 'lodash';

const GenericSelect = ({ placeHolder, items, values, handleFilterChanged, allOption, isLoading = false }) => {
  let options = orderBy(
    map(items, (item) => {
      return {
        value: item.key,
        label: item.text
      };
    }),
    ['label']
  );

  if (allOption && options.length > 0) {
    options = [{ value: allOption.key, label: allOption.text }, ...options];
  }

  const valuesLabels = map(
    filter(options, (option) => includes(values, option.value)),
    (item) => item.label
  );
  const valueLabel = valuesLabels.length > 0 ? { label: valuesLabels.join(', ') } : '';

  return (
    <React.Fragment>
      <SelectBox
        value={valueLabel}
        options={options}
        placeholder={placeHolder}
        closeMenuOnSelect={false}
        customComponents={{
          Option: ({ innerProps, isDisabled, data }) => {
            const isSelected = includes(values, data.value);

            return !isDisabled ? (
              <div {...innerProps}>
                <div className='builder-flex builder-flex-row builder-py-1 hover:builder-bg-gray-100 builder-cursor-pointer hover:builder-text-primary builder-pl-5' onClick={() => handleFilterChanged(data)}>
                  <div className='flex items-center'>
                    <div className='builder-flex builder-items-center builder-justify-center builder-rounded builder-border builder-border-primary builder-mr-2' style={{ height: 16, width: 16 }}>
                      {isSelected && <i className='far fa-check builder-font-bold' style={{ fontSize: 10, marginTop: 2 }} />}
                    </div>
                  </div>
                  <div className={`builder-flex builder-flex-row builder-justify-between builder-flex-1 builder-relative ${isSelected ? 'builder-font-bold' : 'builder-font-normal'}`}>
                    <div className='builder-capitalize'>{data.label}</div>
                  </div>
                </div>
              </div>
            ) : null;
          }
        }}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default GenericSelect;

import React, { useState } from 'react';
import TextInput from '../editors/components/TextInput';

const PageAnalyticsEditor = ({ page, onPageFieldChange = (field, value) => null, context }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='builder-flex builder-flex-col'>
      <div className='builder-flex builder-justify-between builder-items-center builder-py-2 builder-border-b builder-border-gray-300 builder-shadow-sm builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <h3 className='builder-font-bold builder-text-lg builder-ml-4'>Page analytics</h3>
        <i className={`fas builder-mr-4 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
      </div>
      <div className={`${isOpen ? 'builder-flex builder-flex-col' : 'builder-hidden'}`}>
        <div className=''>
          <div className='builder-flex builder-flex-col builder-p-4'>
            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 '>Name</label>
            <TextInput placeholder='name' className='builder-border builder-border-gray-300 builder-rounded-md' value={page ? page.analyticsName : ''} onChanged={(value) => onPageFieldChange('analyticsName', value)} />
          </div>
          <div className='builder-flex builder-flex-col builder-p-4'>
            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 '>Type</label>
            <TextInput placeholder='name' className='builder-border builder-border-gray-300 builder-rounded-md' value={page ? page.analyticsType : ''} onChanged={(value) => onPageFieldChange('analyticsType', value)} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageAnalyticsEditor;

import React from 'react';
import ButtonWithIndicator from '../../../../../../components/common/ButtonWithIndicator';
import Search from '../../../../components/Search';
import SorterBadge from '../../../../components/SorterBadge';
import ButtonUploadFromExcel from '../../../../components/ButtonUploadFromExcel';
import { FILTER_TYPES } from '../../constants';
import { find, map } from 'lodash';
import ButtonReleaseRedirects from './ButtonReleaseRedirects';
import StatusFilter from './StatusFilter';
import BadgeWithIcon from '../../../../components/BadgeWithIcon';

const Header = ({ onCancel, filters, onRemoveFilter, redirectIdsToDelete, onBulkDelete, onAdd, onSearch, buttonsDisabled, onLoadedFromFile, redirects, fetchingStatuses, onApplyStatusFilter, context }) => {
  const sortFilter = find(filters, (filter) => filter.type === FILTER_TYPES.SORT);
  const searchFilter = find(filters, (filter) => filter.type === FILTER_TYPES.SEARCH);
  const statusFilter = find(filters, (filter) => filter.type === FILTER_TYPES.STATUS);

  return (
    <div>
      <div className='builder-flex builder-justify-between builder-items-center builder-border-b builder-border-gray-200 builder-pb-4'>
        <div className='builder-flex builder-items-center builder-space-x-4 builder-divide-x builder-divide-gray-200'>
          <div>
            <Search onChange={onSearch} value={searchFilter ? searchFilter.value : ''} dataTestId='redirect-search-input-button' placeholder='Search for from path or to path' />
          </div>

          <div className='builder-flex builder-items-center builder-pl-4'>
            <div className='builder-mr-4'>Filter by status:</div>

            <StatusFilter onStatusClick={onApplyStatusFilter} fetchingStatuses={fetchingStatuses} selectedStatuses={statusFilter?.value ? statusFilter.value : []} redirects={redirects} />
          </div>
        </div>

        <div className='builder-flex builder-items-center builder-space-x-2'>
          {redirectIdsToDelete && redirectIdsToDelete.length > 0 && (
            <ButtonWithIndicator
              onClick={onBulkDelete}
              dataTestId='redirect-bulk-delete-button'
              icon='fal fa-trash'
              text='Delete'
              disabled={buttonsDisabled}
              colorClass='builder-bg-red-700 hover:builder-bg-red-800 builder-transition-all builder-text-white'
              borderClass='builder-border builder-border-red-700'
            />
          )}

          <ButtonReleaseRedirects />

          {onLoadedFromFile && <ButtonUploadFromExcel onLoaded={onLoadedFromFile} disabled={buttonsDisabled} helperText='The file needs to have next columns: From Path, To Path and Type.' />}

          {onAdd && (
            <ButtonWithIndicator onClick={onAdd} dataTestId='redirect-add-button' icon='fal fa-plus' text='Add' disabled={buttonsDisabled} colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-primary' />
          )}
        </div>
      </div>

      <div className='builder-flex builder-items-center builder-mt-4 builder-space-x-4 builder-divide-x builder-divide-gray-200'>
        {!sortFilter && !statusFilter && (
          <div className='builder-flex builder-items-center builder-text-gray-500' style={{ minHeight: 26 }}>
            No filters applied.
          </div>
        )}
        {sortFilter && (
          <div data-testid='redirect-sort-filter'>
            <SorterBadge sorter={sortFilter} onClear={() => onRemoveFilter(FILTER_TYPES.SORT)} dataTestId='redirect-sort-clear-filter-button' />
          </div>
        )}

        {statusFilter && statusFilter?.value?.length > 0 && (
          <div className={`builder-flex builder-items-center builder-space-x-2 ${sortFilter ? 'builder-ml-4' : ''}`}>
            {map(statusFilter?.value, (value) => {
              return <BadgeWithIcon icon='fal fa-times' text={value} onClick={() => onApplyStatusFilter(value)} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

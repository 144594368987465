import React, { useContext, useState } from 'react';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { modalOpenHandler, undoHandler } from '../../data/actions';
import useBuilderActions from '../useBuilderActions';
import { DispatchContext, StateContext } from '../../../App';
import { MODAL_TYPES } from '../../data/reducers';
import { hasChanges } from '../../../utils';
import { find, includes } from 'lodash';
import { routes } from '../../data/constants';

const URLS_HIDE_GENERAL_SAVE_BUTTON = [
  routes['ADVERTISEMENTS'],
  routes['ADVERTISEMENT_DETAIL'],
  routes['BLOCKS'],
  routes['BLOCKS_DETAIL'],
  routes['CAMPAIGNS'],
  routes['CAMPAIGN_DETAIL'],
  routes['FAQ_OVERVIEW'],
  routes['FAQ_TOPICS'],
  routes['FAQ_CATEGORY_DETAIL'],
  routes['FAQ_QUESTION_GROUP_DETAIL'],
  routes['FAQ_QUESTION_DETAIL'],
  routes['VEHICLE_FILTERS'],
  routes['VEHICLE_FILTERS_DETAIL']
];

const HeaderMenu = ({ onChangeEnvironment }) => {
  const { updateWebsite, draftDelete, loading } = useBuilderActions();
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useContext(DispatchContext);
  const state = useContext(StateContext);

  const handleUndo = () => {
    undoHandler(dispatch);
  };

  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;
  const hasSiteChanges = hasChanges(state.lastSavedAsJson, state.history[state.history.length - 1]);

  const currentUrl = window.location.href;
  const checkHideGeneralSaveButton = (urls, url) => {
    for (const key in urls) {
      if (includes(url, urls[key])) {
        return true;
      }
    }
    return false;
  };
  const isSaveHidden = checkHideGeneralSaveButton(URLS_HIDE_GENERAL_SAVE_BUTTON, currentUrl);

  const renderDraftButton = () => {
    return (
      <div className='builder-relative builder-ml-2' onClick={() => setMenuOpen(!menuOpen)}>
        <div className='builder-flex builder-justify-between builder-items-center builder-px-4 builder-py-2 builder-bg-white builder-border builder-border-gray-300 ' style={{ minWidth: 220 }}>
          <div className='builder-flex builder-items-center'>
            <i className='fal fa-cloud-upload builder-mr-2' /> Publish
          </div>
          <i className='fal fa-chevron-down' />
        </div>
        {menuOpen && (
          <div className='builder-absolute builder-inset-x-0 builder-bg-white builder-border-l builder-py-1 builder-border-r builder-border-b builder-border-gray-300' style={{ top: '100%' }}>
            <ButtonWithIndicator loading={loading} onClick={() => modalOpenHandler(dispatch, MODAL_TYPES.PUBLISH_WEBSITE)} borderClass='' className='hover:builder-underline' text='Publish draft' disabled={hasSiteChanges} />

            <ButtonWithIndicator loading={loading} onClick={() => modalOpenHandler(dispatch, MODAL_TYPES.PUBLISH_DRAFT_TO_PRODUCTION)} text='Publish Draft To Production' borderClass='' className='hover:builder-underline' disabled={hasSiteChanges} />
          </div>
        )}
      </div>
    );
  };

  const renderProductionButton = () => {
    if (state && state.configuration && state.configuration.builder && state.configuration.builder.publish !== undefined) {
      const publishEnabled = Boolean(state.configuration.builder.publish);
      if (!publishEnabled) {
        return null;
      }
    }

    return <ButtonWithIndicator loading={loading} onClick={() => modalOpenHandler(dispatch, MODAL_TYPES.PUBLISH_WEBSITE)} icon='fal fa-cloud-upload' text='Publish' disabled={hasSiteChanges} />;
  };

  const handleDeleteDraft = () => {
    draftDelete(currentWebsite.id);
    const production = find(state.websites, (website) => website.isProduction === true);
    onChangeEnvironment(production.id);
  };

  return (
    <div className='builder-flex builder-flex-1 builder-items-center builder-justify-end builder-p-2 builder-cursor-pointer'>
      {!isCurrentSiteProduction && (
        <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 mr-2' onClick={handleDeleteDraft}>
          Delete Draft
        </div>
      )}

      <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2' onClick={handleUndo}>
        <i className='fal fa-undo' />
      </div>

      {isCurrentSiteProduction ? renderProductionButton() : renderDraftButton()}

      {!isSaveHidden && <ButtonWithIndicator loading={loading} onClick={() => updateWebsite()} icon='fal fa-save' text='Save' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />}
    </div>
  );
};

export default HeaderMenu;
